import React from "react";

export const GhostWaveHappy = () => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 121.56 139.74">
    <defs></defs>
    <g id="Layer_2" data-name="Layer 2">
      <g id="Layer_1-2" data-name="Layer 1">
        <path
          fill="#f09"
          d="M110.46,83c11.3-18.87,16.39-42.48,3.48-58.35,3.71-13.59,2-26.19-13.89-10.56l-.69-.34a58.07,58.07,0,0,0-20.87-2.46C70.51-8.37,64.68.56,62.38,15.94,51.68,21.6,43.54,31.81,36.76,44.17,31,38.82,24.48,32,20.54,25.16c0,0,1.41,12.61,11.46,28.44C20.28,78.73,12.35,109.45,0,129.42c7.2-3.22,24.93-14.48,31.51-18.84C27.17,118,17.75,132.65,12.8,139.74c14.07-7.1,24-10.32,37.94-20-7.3,5.08-11.26,11.59-16.7,18.72,25.42-10.31,41.76-16.3,63.81-38.95,1.88-1.93,3.71-4,5.48-6.24,2.43,7.3,4.69,14.65,6.73,22.06-.59-2.15.75-6.21.9-8.51q.3-4.47.23-8.94c-.08-4.86-.43-9.76-.72-14.6C110.47,83.19,110.46,83.1,110.46,83Z"
        />
        <circle className="cls-2" cx="75.76" cy="25.85" r="3.55" />
        <circle fill="#2c0034" cx="104.58" cy="28.51" r="3.55" />
        <path
          className="cls-2"
          d="M82,33.39a12.53,12.53,0,0,0,7.54,4.88s6.21,0,9.76-4.44C99.26,33.83,82.41,33,82,33.39Z"
        />
      </g>
    </g>
  </svg>
);

export const WordmarkGhostWaveHappy = () => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 392.98 291.8">
    <path
      d="M147.35,115.34c0,9.75-8.09,17.21-17.83,17.21-10,0-18.05-7.46-18.05-17.21,0-10,8.09-17.42,18.05-17.42C139.26,97.92,147.35,105.39,147.35,115.34Zm-3.94,136.79h-28V152.35l28-.1Z"
      fill="#f3ffe4"
    />
    <path d="M393,252.13H365V100l28-.17Z" fill="#f3ffe4" />
    <path
      d="M48.34,152.66S0,152.65,0,203.36c0,0-1.2,48.26,49.72,48.73l32.11-.1s3,15.22-22.71,15.22l-49.21-.07V291.7H59s52.3,4,52.3-39.61c0,0,.27-99.81.27-99.43Zm34.08,74.41H53.68c-8.44,0-14.38-1.84-18.14-5.62C29.46,215.33,29,204.68,29,203.77v-.41c0-21.94,14.7-25.51,23.32-25.68H82.55Z"
      fill="#f3ffe4"
    />
    <path
      d="M194.53,152.66s-48.33,0-48.33,50.7c0,0-1.19,48.73,49.72,48.73h31.89s3.37,15.25-22.38,15.25l-49.33-.2V291.7h49.13s52.29,4,52.29-39.61c0,0,.27-99.81.27-99.43Zm33.69,74.41H199.49c-8.45,0-14.38-1.84-18.15-5.62-6.08-6.12-6.53-16.77-6.56-17.68v-.41c0-21.94,14.7-25.51,23.32-25.68h30.25Z"
      fill="#f3ffe4"
    />
    <path
      d="M297.93,152.66s-48.33,0-48.33,50.7c0,0-1.19,48.73,49.72,48.73h31.89s3.37,15.25-22.38,15.25l-54.87-.2V291.7h54.67s52.29,4,52.29-39.61c0,0,.27-99.81.27-99.43Zm33.69,74.41H302.89c-8.45,0-14.38-1.84-18.15-5.62-6.08-6.12-6.53-16.77-6.56-17.68v-.41c0-21.94,14.7-25.51,23.32-25.68h30.25Z"
      fill="#f3ffe4"
    />
    <path
      d="M326.34,83c11.3-18.87,16.39-42.48,3.48-58.35,3.71-13.59,2-26.19-13.89-10.56l-.69-.34a58.07,58.07,0,0,0-20.87-2.46c-8-19.66-13.81-10.73-16.11,4.65-10.7,5.66-18.84,15.87-25.62,28.23-5.76-5.35-12.28-12.22-16.22-19,0,0,1.41,12.61,11.46,28.44-11.72,25.13-19.65,55.85-32,75.82,7.2-3.22,24.93-14.48,31.51-18.84-4.34,7.45-13.76,22.07-18.71,29.16,14.07-7.1,24-10.32,37.94-20-7.3,5.08-11.26,11.59-16.7,18.72,25.42-10.31,41.76-16.3,63.81-38.95,1.88-1.93,3.71-4,5.48-6.24,2.43,7.3,4.69,14.65,6.73,22.06-.59-2.15.75-6.21.9-8.51q.3-4.47.23-8.94c-.08-4.86-.43-9.76-.72-14.6C326.35,83.19,326.34,83.1,326.34,83Z"
      fill="#f09"
    />
    <circle cx="291.64" cy="25.85" r="3.55" fill="#2c0034" />
    <circle cx="320.46" cy="28.51" r="3.55" fill="#2c0034" />
    <path
      d="M297.84,33.39a12.53,12.53,0,0,0,7.54,4.88s6.21,0,9.76-4.44C315.14,33.83,298.29,33,297.84,33.39Z"
      fill="#2c0034"
    />
  </svg>
);

export const NetNano = () => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 131.87 154.58">
    <g id="Layer_2" data-name="Layer 2">
      <g id="Layer_1-2" data-name="Layer 1">
        <g id="Layer_2-2" data-name="Layer 2">
          <g id="Layer_1-2-2" data-name="Layer 1-2">
            <path
              d="M40.32,116.67C36.07,123.93,27,138.1,22,145.35l.15.24c12.34-6.2,21.54-9.47,33.2-16.93-1.76-2.94-.39-7.78,4.08-7.78,6.45,0,6.44,10,0,10A4.53,4.53,0,0,1,55.55,129c-4.81,4.38-8.18,9.54-12.38,15.09a1.76,1.76,0,0,1,.14.23c25.16-10.19,41.44-16.25,63.35-38.76a80.08,80.08,0,0,0,5.48-6.24q2.61,7.83,4.93,15.75a5.47,5.47,0,0,1,1.67-.26,4.56,4.56,0,0,1,.75.06,18,18,0,0,0,.28-2q.3-4.47.23-8.94c-.08-4.86-.43-9.76-.72-14.6,0-.09,0-.18,0-.28,11.3-18.87,16.39-42.48,3.48-58.35,1.34-4.9,2-9.67,1.48-13a4.92,4.92,0,0,1-2.28.52,4.66,4.66,0,0,1-4.8-4.44c-2.18,1-4.91,3.07-8.29,6.39l-.69-.34A58,58,0,0,0,87.3,17.38a34.28,34.28,0,0,0-4.92-9,4.53,4.53,0,0,1-4.2,2.43A4.61,4.61,0,0,1,74.74,9.4C73.18,12.27,72,16.77,71.19,22,60.49,27.69,52.35,37.9,45.57,50.26,40.21,45.28,34.2,39,30.21,32.67l-.59.25c.74,3.92,3.31,14.36,11.19,26.77C29.29,84.4,21.42,114.51,9.42,134.49a4,4,0,0,1,.39.55c7.72-3.72,24.22-14.2,30.51-18.37"
              fill="none"
              stroke="#fff"
              stroke-miterlimit="10"
              stroke-width="3"
            />
          </g>
        </g>
        <circle
          cx="121.87"
          cy="13.6"
          r="4.59"
          fill="none"
          stroke="#fff"
          stroke-miterlimit="10"
          stroke-width="3"
        />
        <circle
          cx="78.25"
          cy="6.09"
          r="4.59"
          fill="none"
          stroke="#fff"
          stroke-miterlimit="10"
          stroke-width="3"
        />
        <circle
          cx="118.6"
          cy="119.6"
          r="4.59"
          fill="none"
          stroke="#fff"
          stroke-miterlimit="10"
          stroke-width="3"
        />
        <circle
          cx="39.47"
          cy="147.01"
          r="4.59"
          fill="none"
          stroke="#fff"
          stroke-miterlimit="10"
          stroke-width="3"
        />
        <circle
          cx="59.32"
          cy="125.98"
          r="4.59"
          fill="none"
          stroke="#fff"
          stroke-miterlimit="10"
          stroke-width="3"
        />
        <circle
          cx="18.18"
          cy="148.49"
          r="4.59"
          fill="none"
          stroke="#fff"
          stroke-miterlimit="10"
          stroke-width="3"
        />
        <circle
          cx="44.06"
          cy="112.92"
          r="4.59"
          fill="none"
          stroke="#fff"
          stroke-miterlimit="10"
          stroke-width="3"
        />
        <circle
          cx="6.09"
          cy="137.83"
          r="4.59"
          fill="none"
          stroke="#fff"
          stroke-miterlimit="10"
          stroke-width="3"
        />
        <circle
          cx="27.94"
          cy="28.04"
          r="4.59"
          fill="none"
          stroke="#fff"
          stroke-miterlimit="10"
          stroke-width="3"
        />
      </g>
    </g>
  </svg>
);

export const NetworkingLogo = (props: React.SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 488.95 154.58"
    {...props}
  >
    <g id="Layer_2" data-name="Layer 2">
      <g id="Layer_1-2" data-name="Layer 1">
        <g id="Layer_2-2" data-name="Layer 2">
          <g id="Layer_1-2-2" data-name="Layer 1-2">
            <path
              d="M40.32,116.67C36.07,123.93,27,138.1,22,145.35l.15.24c12.34-6.2,21.54-9.47,33.2-16.93-1.76-2.94-.39-7.78,4.08-7.78,6.45,0,6.44,10,0,10A4.53,4.53,0,0,1,55.55,129c-4.81,4.38-8.18,9.54-12.38,15.09a1.76,1.76,0,0,1,.14.23c25.16-10.19,41.44-16.25,63.35-38.76a80.08,80.08,0,0,0,5.48-6.24q2.61,7.83,4.93,15.75a5.47,5.47,0,0,1,1.67-.26,4.56,4.56,0,0,1,.75.06,18,18,0,0,0,.28-2q.3-4.47.23-8.94c-.08-4.86-.43-9.76-.72-14.6,0-.09,0-.18,0-.28,11.3-18.87,16.39-42.48,3.48-58.35,1.34-4.9,2-9.67,1.48-13a4.92,4.92,0,0,1-2.28.52,4.66,4.66,0,0,1-4.8-4.44c-2.18,1-4.91,3.07-8.29,6.39l-.69-.34A58,58,0,0,0,87.3,17.38a34.28,34.28,0,0,0-4.92-9,4.53,4.53,0,0,1-4.2,2.43A4.61,4.61,0,0,1,74.74,9.4C73.18,12.27,72,16.77,71.19,22,60.49,27.69,52.35,37.9,45.57,50.26,40.21,45.28,34.2,39,30.21,32.67l-.59.25c.74,3.92,3.31,14.36,11.19,26.77C29.29,84.4,21.42,114.51,9.42,134.49a4,4,0,0,1,.39.55c7.72-3.72,24.22-14.2,30.51-18.37"
              fill="none"
              stroke="#fff"
              stroke-miterlimit="10"
              stroke-width="3"
            />
          </g>
        </g>
        <circle
          cx="121.87"
          cy="13.6"
          r="4.59"
          fill="none"
          stroke="#fff"
          stroke-miterlimit="10"
          stroke-width="3"
        />
        <circle
          cx="78.25"
          cy="6.09"
          r="4.59"
          fill="none"
          stroke="#fff"
          stroke-miterlimit="10"
          stroke-width="3"
        />
        <circle
          cx="118.6"
          cy="119.6"
          r="4.59"
          fill="none"
          stroke="#fff"
          stroke-miterlimit="10"
          stroke-width="3"
        />
        <circle
          cx="39.47"
          cy="147.01"
          r="4.59"
          fill="none"
          stroke="#fff"
          stroke-miterlimit="10"
          stroke-width="3"
        />
        <circle
          cx="59.32"
          cy="125.98"
          r="4.59"
          fill="none"
          stroke="#fff"
          stroke-miterlimit="10"
          stroke-width="3"
        />
        <circle
          cx="18.18"
          cy="148.49"
          r="4.59"
          fill="none"
          stroke="#fff"
          stroke-miterlimit="10"
          stroke-width="3"
        />
        <circle
          cx="44.06"
          cy="112.92"
          r="4.59"
          fill="none"
          stroke="#fff"
          stroke-miterlimit="10"
          stroke-width="3"
        />
        <circle
          cx="6.09"
          cy="137.83"
          r="4.59"
          fill="none"
          stroke="#fff"
          stroke-miterlimit="10"
          stroke-width="3"
        />
        <circle
          cx="27.94"
          cy="28.04"
          r="4.59"
          fill="none"
          stroke="#fff"
          stroke-miterlimit="10"
          stroke-width="3"
        />
        <path
          d="M197.17,75h-4.36l-.35-3.16a8,8,0,0,1-3.14,2.67,10.9,10.9,0,0,1-5,1.07,13.43,13.43,0,0,1-9.77-3.93,14,14,0,0,1-4-10.44,13.9,13.9,0,0,1,14.15-14.42,13.22,13.22,0,0,1,8.27,2.46,12,12,0,0,1,4.35,6l-5.15,1.83a7.39,7.39,0,0,0-7.47-5.3,8.65,8.65,0,0,0-6.1,2.41q-2.59,2.41-2.59,7a9.4,9.4,0,0,0,2.51,7,8.49,8.49,0,0,0,6.21,2.49A7.33,7.33,0,0,0,190,68.89a6.58,6.58,0,0,0,2.15-3.64h-8.73V60.57h13.79Z"
          fill="#fff"
        />
        <path
          d="M201.57,49.51a3.13,3.13,0,0,1,.94-2.28,3,3,0,0,1,2.26-.95A3.18,3.18,0,0,1,208,49.51a3,3,0,0,1-.93,2.22,3.1,3.1,0,0,1-2.26.93,3.07,3.07,0,0,1-2.26-.93A3,3,0,0,1,201.57,49.51ZM207.38,75H202.2V55.78h5.18Z"
          fill="#fff"
        />
        <path
          d="M211.54,76l4.68-1.25a4.17,4.17,0,0,0,1.48,2.61,4.47,4.47,0,0,0,3,1q5.1,0,5.1-5.34v-1.4a5,5,0,0,1-2,1.75,7.16,7.16,0,0,1-3.31.7,8.46,8.46,0,0,1-6.37-2.65,9.17,9.17,0,0,1-2.56-6.66,9.37,9.37,0,0,1,2.54-6.62,8.32,8.32,0,0,1,6.39-2.73,7.22,7.22,0,0,1,3.5.76A4.39,4.39,0,0,1,225.92,58V55.78h5v17.1a10.56,10.56,0,0,1-2.5,7.19q-2.49,2.87-7.56,2.86a9.88,9.88,0,0,1-6.33-2A7.6,7.6,0,0,1,211.54,76Zm9.82-6.35a4.29,4.29,0,0,0,3.25-1.32,4.92,4.92,0,0,0,1.27-3.55,4.8,4.8,0,0,0-1.3-3.51A4.38,4.38,0,0,0,221.36,60a4.44,4.44,0,0,0-3.29,1.32,4.81,4.81,0,0,0-1.31,3.51,4.89,4.89,0,0,0,1.29,3.55A4.42,4.42,0,0,0,221.36,69.65Z"
          fill="#fff"
        />
        <path
          d="M234.8,76l4.68-1.25A4.17,4.17,0,0,0,241,77.36a4.47,4.47,0,0,0,3,1q5.1,0,5.1-5.34v-1.4a5.05,5.05,0,0,1-2,1.75,7.13,7.13,0,0,1-3.31.7,8.47,8.47,0,0,1-6.37-2.65,9.2,9.2,0,0,1-2.55-6.66,9.36,9.36,0,0,1,2.53-6.62,8.35,8.35,0,0,1,6.39-2.73,7.29,7.29,0,0,1,3.51.76,4.5,4.5,0,0,1,2,1.85V55.78h5v17.1a10.56,10.56,0,0,1-2.49,7.19q-2.49,2.87-7.56,2.86a9.86,9.86,0,0,1-6.33-2A7.6,7.6,0,0,1,234.8,76Zm9.82-6.35a4.3,4.3,0,0,0,3.25-1.32,5,5,0,0,0,1.27-3.55,4.81,4.81,0,0,0-1.31-3.51A4.34,4.34,0,0,0,244.62,60a4.42,4.42,0,0,0-3.29,1.32A4.81,4.81,0,0,0,240,64.78a4.89,4.89,0,0,0,1.29,3.55A4.41,4.41,0,0,0,244.62,69.65Z"
          fill="#fff"
        />
        <path d="M264.6,75h-5.18V46.74h5.18Z" fill="#fff" />
        <path
          d="M303.71,75h-5.65l-12.54-19.6V75h-5.38V47.33h6.71L298.34,65.6V47.33h5.37Z"
          fill="#fff"
        />
        <path
          d="M322.37,68.17l4.33,1.28a8.3,8.3,0,0,1-3.06,4.37A9.18,9.18,0,0,1,318,75.53a9.74,9.74,0,0,1-7-2.78,9.91,9.91,0,0,1-2.88-7.46,9.87,9.87,0,0,1,2.8-7.27,9.79,9.79,0,0,1,13.58-.17A10.16,10.16,0,0,1,327,65.13c0,.21,0,.44,0,.7s0,.47,0,.63l0,.27H313.18a4.22,4.22,0,0,0,1.48,3.11A4.84,4.84,0,0,0,318,71.09,4.19,4.19,0,0,0,322.37,68.17Zm-9.07-5h8.57a4.06,4.06,0,0,0-1.15-2.69,4.25,4.25,0,0,0-3.14-1.09,4.12,4.12,0,0,0-3,1.17A3.91,3.91,0,0,0,313.3,63.18Z"
          fill="#fff"
        />
        <path
          d="M337.45,50.05v5.73h3.86v4.6h-3.86v8a2.29,2.29,0,0,0,.54,1.72,2.52,2.52,0,0,0,1.72.5,6.48,6.48,0,0,0,1.6-.15v4.28a7.81,7.81,0,0,1-2.93.47,6.13,6.13,0,0,1-4.44-1.58,5.75,5.75,0,0,1-1.63-4.34V60.38h-3.47v-4.6h1a2.84,2.84,0,0,0,2.21-.84,3.17,3.17,0,0,0,.75-2.2V50.05Z"
          fill="#fff"
        />
        <path
          d="M356.61,55.78h5.46l4.13,12.35,3.54-12.35h5.15l-6,19.17H363.7l-4.48-13.09L354.82,75h-5.26l-6.11-19.17h5.45l3.55,12.31Z"
          fill="#fff"
        />
        <path
          d="M379,58.08a10.42,10.42,0,0,1,14.38,0,10.71,10.71,0,0,1,0,14.57,10.42,10.42,0,0,1-14.38,0,10.71,10.71,0,0,1,0-14.57Zm3.74,11.3a4.85,4.85,0,0,0,6.9,0,5.47,5.47,0,0,0,1.42-4,5.46,5.46,0,0,0-1.42-4,4.85,4.85,0,0,0-6.9,0,6.39,6.39,0,0,0,0,8Z"
          fill="#fff"
        />
        <path
          d="M412.05,55.7v5.22a8.24,8.24,0,0,0-1.56-.15A5.06,5.06,0,0,0,406.91,62a5.46,5.46,0,0,0-1.37,4.15V75h-5.18V55.78h5v2.84q1.39-3,5.45-3C411.13,55.63,411.53,55.65,412.05,55.7Z"
          fill="#fff"
        />
        <path
          d="M434.29,55.78l-7.67,8.06L434.45,75H428.1L423,67.62l-2.14,2.26V75h-5.18V46.74h5.18V62.91l6.62-7.13Z"
          fill="#fff"
        />
        <path
          d="M436.86,49.51a3.17,3.17,0,0,1,.94-2.28,3,3,0,0,1,2.26-.95,3.18,3.18,0,0,1,3.19,3.23,3,3,0,0,1-.93,2.22,3.21,3.21,0,0,1-4.52,0A3,3,0,0,1,436.86,49.51ZM442.67,75h-5.18V55.78h5.18Z"
          fill="#fff"
        />
        <path
          d="M453.38,63.92V75H448.2V55.78h5v2.38A5.42,5.42,0,0,1,455.56,56a7.25,7.25,0,0,1,3.2-.74,6.33,6.33,0,0,1,5.16,2.13,8.25,8.25,0,0,1,1.77,5.47V75h-5.18V63.77a4.15,4.15,0,0,0-.88-2.77,3.74,3.74,0,0,0-5.28.08A4.18,4.18,0,0,0,453.38,63.92Z"
          fill="#fff"
        />
        <path
          d="M469.59,76l4.67-1.25a4.21,4.21,0,0,0,1.48,2.61,4.51,4.51,0,0,0,3,1q5.1,0,5.1-5.34v-1.4a5,5,0,0,1-2,1.75,7.13,7.13,0,0,1-3.31.7,8.46,8.46,0,0,1-6.37-2.65,9.2,9.2,0,0,1-2.55-6.66,9.36,9.36,0,0,1,2.53-6.62,8.35,8.35,0,0,1,6.39-2.73,7.22,7.22,0,0,1,3.5.76A4.39,4.39,0,0,1,484,58V55.78h5v17.1a10.51,10.51,0,0,1-2.5,7.19q-2.49,2.87-7.55,2.86a9.86,9.86,0,0,1-6.33-2A7.56,7.56,0,0,1,469.59,76Zm9.81-6.35a4.33,4.33,0,0,0,3.26-1.32,5,5,0,0,0,1.26-3.55,4.8,4.8,0,0,0-1.3-3.51A4.34,4.34,0,0,0,479.4,60a4.44,4.44,0,0,0-3.29,1.32,4.8,4.8,0,0,0-1.3,3.51,4.88,4.88,0,0,0,1.28,3.55A4.42,4.42,0,0,0,479.4,69.65Z"
          fill="#fff"
        />
        <path
          d="M184.65,115l-1.58-4.36h-7.76L173.73,115H169.8l7.32-19.08h4.3L188.69,115Zm-5.46-15-2.64,7.21h5.28Z"
          fill="#fff"
        />
        <path
          d="M203.87,100.48l-3.34,1a3.4,3.4,0,0,0-1-1.85,3.31,3.31,0,0,0-2.45-.84,3.2,3.2,0,0,0-2.15.71,2.19,2.19,0,0,0-.84,1.71c0,1.17.7,1.89,2.08,2.16l2.66.51a6.81,6.81,0,0,1,3.93,2,5.17,5.17,0,0,1,1.4,3.6,5.52,5.52,0,0,1-1.87,4.14,7,7,0,0,1-5,1.76,9.33,9.33,0,0,1-3.26-.54,6.29,6.29,0,0,1-2.34-1.44,7.39,7.39,0,0,1-1.38-1.91,5.83,5.83,0,0,1-.61-2.09l3.45-.91a3.92,3.92,0,0,0,1.19,2.56,4.13,4.13,0,0,0,3,1,3.59,3.59,0,0,0,2.27-.65,2,2,0,0,0,.82-1.69,2,2,0,0,0-.6-1.44,3.2,3.2,0,0,0-1.71-.82l-2.67-.54a6.86,6.86,0,0,1-3.66-1.91,5,5,0,0,1-1.34-3.56,5.46,5.46,0,0,1,2-4.25,6.83,6.83,0,0,1,4.7-1.72A8.62,8.62,0,0,1,200,96,5.49,5.49,0,0,1,202,97.21a6.5,6.5,0,0,1,1.21,1.57A6.21,6.21,0,0,1,203.87,100.48Z"
          fill="#fff"
        />
        <path
          d="M210.87,107.26l-1.64-2.58,4.57-3.93h-7.64v-3.2h12.35v3L214,104.3a5.26,5.26,0,0,1,3.57,1.55,5.09,5.09,0,0,1,1.49,3.78,5.4,5.4,0,0,1-1.83,4.08,7.78,7.78,0,0,1-9.63.08,5.68,5.68,0,0,1-2-3.94l3.58-.7a3.15,3.15,0,0,0,.93,2.2,3.38,3.38,0,0,0,4.4.11,2.46,2.46,0,0,0,.81-1.88,2.38,2.38,0,0,0-.79-1.9,3,3,0,0,0-2.06-.69A4.66,4.66,0,0,0,210.87,107.26Z"
          fill="#fff"
        />
        <path
          d="M221.31,114.45l.92-3a6.23,6.23,0,0,0,3.09.76,5.63,5.63,0,0,0,3.89-1.3,5.21,5.21,0,0,0,1.66-3.66,3.91,3.91,0,0,1-1.63,1.38,5.5,5.5,0,0,1-2.41.51,6.07,6.07,0,0,1-4.28-1.62,5.48,5.48,0,0,1-1.75-4.25,5.67,5.67,0,0,1,1.9-4.41,6.87,6.87,0,0,1,4.8-1.73,6.55,6.55,0,0,1,5,2.14,8.75,8.75,0,0,1,2,6.13c0,3.17-.78,5.64-2.34,7.38s-3.82,2.63-6.78,2.63A7.53,7.53,0,0,1,221.31,114.45Zm8.2-9.21a2.67,2.67,0,0,0,.87-2.07,2.7,2.7,0,0,0-.87-2.07,3,3,0,0,0-2.14-.81,3,3,0,0,0-2.11.81,2.68,2.68,0,0,0-.88,2.07,2.73,2.73,0,0,0,.86,2.09,3.25,3.25,0,0,0,4.27,0Z"
          fill="#fff"
        />
        <path
          d="M237,114.45l.92-3a6.26,6.26,0,0,0,3.09.76,5.61,5.61,0,0,0,3.89-1.3,5.21,5.21,0,0,0,1.66-3.66,3.91,3.91,0,0,1-1.63,1.38,5.5,5.5,0,0,1-2.41.51,6.07,6.07,0,0,1-4.28-1.62,5.52,5.52,0,0,1-1.75-4.25,5.7,5.7,0,0,1,1.9-4.41,6.88,6.88,0,0,1,4.8-1.73,6.55,6.55,0,0,1,5,2.14,8.71,8.71,0,0,1,2,6.13c0,3.17-.78,5.64-2.35,7.38s-3.82,2.63-6.78,2.63A7.53,7.53,0,0,1,237,114.45Zm8.2-9.21a2.67,2.67,0,0,0,.87-2.07,2.7,2.7,0,0,0-.87-2.07,3,3,0,0,0-2.14-.81,3,3,0,0,0-2.11.81,2.68,2.68,0,0,0-.88,2.07,2.73,2.73,0,0,0,.86,2.09,3.25,3.25,0,0,0,4.27,0Z"
          fill="#fff"
        />
        <path
          d="M252,110.12l3.47-.78a2.92,2.92,0,0,0,.93,2.08,3.06,3.06,0,0,0,2.24.85,3.1,3.1,0,0,0,2.16-.78,3,3,0,0,0,0-4.25,3.27,3.27,0,0,0-2.2-.76,3.44,3.44,0,0,0-2.69,1.19c-1.27-.49-2.4-.87-3.39-1.16l2.07-9h9.85v3.2h-7.32l-.86,3.85a4.43,4.43,0,0,1,3.15-1.08,6,6,0,0,1,4.33,1.55,5.59,5.59,0,0,1,1.65,4.29,5.79,5.79,0,0,1-1.83,4.31,6.73,6.73,0,0,1-4.88,1.78,6.85,6.85,0,0,1-4.61-1.59A5.3,5.3,0,0,1,252,110.12Z"
          fill="#fff"
        />
        <path
          d="M271.89,107.26l-1.65-2.58,4.58-3.93h-7.64v-3.2h12.35v3L275,104.3a5.22,5.22,0,0,1,3.56,1.55,5.06,5.06,0,0,1,1.5,3.78,5.4,5.4,0,0,1-1.83,4.08,7.79,7.79,0,0,1-9.64.08,5.66,5.66,0,0,1-2-3.94l3.58-.7a3.15,3.15,0,0,0,.93,2.2,3.38,3.38,0,0,0,4.4.11,2.46,2.46,0,0,0,.81-1.88,2.38,2.38,0,0,0-.8-1.9,3,3,0,0,0-2.06-.69A4.64,4.64,0,0,0,271.89,107.26Z"
          fill="#fff"
        />
        <path
          d="M289.33,115h-3.69V103.33h-4.2v-2.5a5.37,5.37,0,0,0,3.15-1,3.74,3.74,0,0,0,1.54-2.33h3.2Z"
          fill="#fff"
        />
      </g>
    </g>
  </svg>
);
export const Hero = (props: React.Props<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 789 435.13" {...props}>
    <path
      d="M93.71,423.13H757.77a31.17,31.17,0,0,0,30.9-35.59c-4.54-31.07-13.85-58.16-32.66-64.41-27.66-9.18-47.64,36-88,36-60.51,0-77.37-105.94-148-128-31.52-9.84-84.22-6-172,72,11.21-59.74.65-82.57-12-92-19.61-14.62-42.71,5.11-80-8-34.31-12-33.8-35.29-64-52C152,129,91.74,136.9,52,167.13-15.11,218.2.64,314.53,4,335.13a206.81,206.81,0,0,0,6,25.95A87.28,87.28,0,0,0,93.71,423.13Z"
      fill="#2f0034"
      opacity="0.5"
    />
    <path
      d="M564.69,10.64c35.33-39.4,224.33,38.6,224,104-.19,37.58-63,77.4-118,72C577.53,177.49,536.7,41.85,564.69,10.64Z"
      fill="#2f0034"
      opacity="0.75"
    />
    <rect x="36.01" y="131.13" width="68" height="84" rx="4" fill="#1f0023" />
    <rect x="120.01" y="71.13" width="76" height="108" rx="4" fill="#1f0023" />
    <rect x="48.01" y="143.13" width="44" height="60" rx="4" fill="#52084d" />
    <rect x="136.01" y="87.13" width="44" height="76" rx="4" fill="#2f0034" />
    <rect x="132.01" y="191.13" width="44" height="44" rx="8" fill="#1f0023" />
    <rect x="140.01" y="199.13" width="28" height="28" rx="4" fill="#433047" />
    <rect
      x="256.01"
      y="43.97"
      width="488"
      height="271.16"
      rx="12"
      fill="#1f0023"
    />
    <rect
      x="244.01"
      y="27.13"
      width="488"
      height="276"
      rx="12"
      fill="#433047"
    />
    <rect
      x="240.01"
      y="31.97"
      width="488"
      height="271.16"
      rx="12"
      fill="#59425e"
    />
    <rect x="317.48" y="61.03" width="276" height="242.11" fill="#59425e" />
    <rect x="320.01" y="71.13" width="268" height="176" rx="6" fill="#705370" />
    <rect x="324.01" y="75.13" width="260" height="168" rx="4" fill="#c3017b" />
    <path
      d="M580,75.13H328a4,4,0,0,0-4,4v34.31l15.16-8.17a20,20,0,0,1,27.69,9.34l21.33,46.91a20,20,0,0,0,29.45,8.26l62.77-42.68a20,20,0,0,1,28.45,6.34l10.8,18.23a20,20,0,0,0,27.66,6.85L584,136V79.13A4,4,0,0,0,580,75.13Z"
      fill="#ff0092"
      opacity="0.25"
    />
    <path
      d="M252,27.13H718a12,12,0,0,1,12,12v16a0,0,0,0,1,0,0H240a0,0,0,0,1,0,0v-16A12,12,0,0,1,252,27.13Z"
      fill="#433047"
    />
    <circle cx="261.01" cy="42.13" r="5" fill="#79eae6" />
    <circle cx="279.01" cy="42.13" r="5" fill="#ff0092" />
    <circle cx="297.01" cy="42.13" r="5" fill="#59425e" />
    <rect x="604.01" y="71.13" width="40" height="8" rx="4" fill="#705370" />
    <rect x="256.01" y="71.13" width="32" height="8" rx="4" fill="#705370" />
    <rect x="664.01" y="71.13" width="48" height="20" rx="10" fill="#2eaf69" />
    <rect x="256.01" y="95.13" width="48" height="4" rx="2" fill="#705370" />
    <rect x="604.01" y="87.13" width="48" height="4" rx="2" fill="#705370" />
    <rect x="256.01" y="111.13" width="40" height="4" rx="2" fill="#705370" />
    <rect x="256.01" y="175.13" width="30" height="4" rx="2" fill="#705370" />
    <rect x="256.01" y="127.13" width="48" height="4" rx="2" fill="#705370" />
    <rect x="256.01" y="159.13" width="36" height="4" rx="2" fill="#705370" />
    <rect x="256.01" y="143.13" width="48" height="4" rx="2" fill="#705370" />
    <rect x="636.01" y="103.13" width="48" height="4" rx="2" fill="#705370" />
    <rect x="636.01" y="111.13" width="24" height="4" rx="2" fill="#705370" />
    <rect x="636.01" y="171.13" width="48" height="4" rx="2" fill="#705370" />
    <rect x="636.01" y="179.13" width="24" height="4" rx="2" fill="#705370" />
    <rect x="636.01" y="235.13" width="48" height="4" rx="2" fill="#705370" />
    <rect x="636.01" y="243.13" width="24" height="4" rx="2" fill="#705370" />
    <rect x="636.01" y="147.13" width="24" height="4" rx="2" fill="#705370" />
    <rect x="664.01" y="147.13" width="24" height="4" rx="2" fill="#705370" />
    <circle cx="616.01" cy="111.13" r="12" fill="#705370" />
    <circle cx="616.01" cy="147.13" r="12" fill="#705370" />
    <circle cx="616.01" cy="179.13" r="12" fill="#705370" />
    <circle cx="616.01" cy="211.13" r="12" fill="#705370" />
    <circle cx="616.01" cy="243.13" r="12" fill="#705370" />
    <rect x="636.01" y="139.13" width="72" height="4" rx="2" fill="#705370" />
    <rect x="636.01" y="211.13" width="24" height="4" rx="2" fill="#705370" />
    <rect x="664.01" y="211.13" width="24" height="4" rx="2" fill="#705370" />
    <rect x="636.01" y="203.13" width="72" height="4" rx="2" fill="#705370" />
    <rect x="604.01" y="267.13" width="108" height="20" rx="4" fill="#705370" />
    <circle cx="498.01" cy="273.13" r="12" fill="#705370" />
    <circle cx="458.01" cy="273.13" r="12" fill="#705370" />
    <circle cx="418.01" cy="273.13" r="12" fill="#705370" />
    <rect
      x="352.01"
      y="219.13"
      width="220"
      height="8"
      rx="4"
      fill="#ff0092"
      opacity="0.25"
    />
    <path
      d="M340,219.13H484a0,0,0,0,1,0,0v8a0,0,0,0,1,0,0H340a4,4,0,0,1-4-4v0A4,4,0,0,1,340,219.13Z"
      fill="#ff0092"
      opacity="0.5"
    />
    <circle cx="484.01" cy="223.13" r="12" fill="#6a0058" />
    <circle cx="484.01" cy="223.13" r="4" fill="#c3017b" />
    <circle cx="300.01" cy="75.13" r="4" fill="#705370" />
    <rect x="672.01" y="79.13" width="24" height="4" rx="2" fill="#fff" />
    <path d="M361.27,229.87Z" fill="#1f0023" />
    <path
      d="M438.72,174.13a8,8,0,0,1-8-8V135.29a8,8,0,0,1,8-8,7.91,7.91,0,0,1,3.59.86l30.81,15.4a8,8,0,0,1,0,14.36l-30.81,15.41a8,8,0,0,1-3.59.85Z"
      fill="#ff0092"
    />
    <path
      d="M438.72,130.25a5,5,0,0,1,2.25.54l30.81,15.41a5,5,0,0,1,0,9L441,170.59a5,5,0,0,1-7.28-4.49V135.29a5,5,0,0,1,5-5m0-6a11,11,0,0,0-11,11V166.1a11,11,0,0,0,11,11,10.87,10.87,0,0,0,4.93-1.17l30.81-15.4a11,11,0,0,0,0-19.73l-30.81-15.41a11,11,0,0,0-4.93-1.17Z"
      fill="#c3017b"
    />
    <path
      d="M602,423.13H707a15,15,0,0,0,15-15h0a15,15,0,0,0-15-15h-3a4,4,0,0,0-4,4h0a4,4,0,0,0,4,4h4a6,6,0,0,1,6,6h0a6,6,0,0,1-6,6h-7.2a11.75,11.75,0,0,1-11.28-8.44h0A28.41,28.41,0,0,0,656,385l-36,8.13H602Z"
      fill="#1f0023"
    />
    <path
      d="M608,399.13h18L662,391a28.35,28.35,0,0,1,23.12,4.86A28.4,28.4,0,0,0,656,385l-36,8.13H602v30h6Z"
      fill="#2f0034"
    />
    <path
      d="M580,413.13a10,10,0,0,0-10,10h20A10,10,0,0,0,580,413.13Z"
      fill="#1f0023"
    />
    <path
      d="M620.41,375.93l-6.08,8.1a4.29,4.29,0,0,1-5.64,1.11,13,13,0,0,0-13.36,0,4.3,4.3,0,0,1-5.65-1.11l-6.07-8.1a2,2,0,0,0-3.6,1.2v28a22,22,0,0,0,44,0v-28A2,2,0,0,0,620.41,375.93Z"
      fill="#1f0023"
    />
    <path
      d="M624,413.13a10,10,0,0,0-10,10h20A10,10,0,0,0,624,413.13Z"
      fill="#1f0023"
    />
    <path
      d="M586,405.13v-26l-2.4-3.2a2,2,0,0,0-3.6,1.2v28a22,22,0,0,0,22,22,21.7,21.7,0,0,0,2.92-.22A22,22,0,0,1,586,405.13Z"
      fill="#2f0034"
    />
    <path
      d="M599.07,413.31l-1.87-2.21c-.41-.49-.12-1.33.46-1.33h4.69c.59,0,.88.84.47,1.33L601,413.31A1.18,1.18,0,0,1,599.07,413.31Z"
      fill="#2f0034"
    />
    <path
      d="M627,413.59a10,10,0,0,0-13,9.54h6A10,10,0,0,1,627,413.59Z"
      fill="#2f0034"
    />
    <path
      d="M583,413.59a10,10,0,0,0-13,9.54h6A10,10,0,0,1,583,413.59Z"
      fill="#2f0034"
    />
    <rect x="556.01" y="423.13" width="94" height="12" fill="#1f0023" />
    <path
      d="M620.41,375.93l-6.08,8.1a4.24,4.24,0,0,1-5.55,1.17,12.94,12.94,0,0,0-13.54,0,4.25,4.25,0,0,1-5.56-1.17l-6.07-8.1a2,2,0,0,0-3.6,1.2v6a2,2,0,0,1,3.6-1.2l7.4,9.87a2,2,0,0,0,2.82.38,13,13,0,0,1,16.36,0,2,2,0,0,0,2.81-.38l7.41-9.87a2,2,0,0,1,3.6,1.2v-6A2,2,0,0,0,620.41,375.93Z"
      fill="#2f0034"
    />
    <path
      d="M620.41,373.94l-6.08,8.1a4.24,4.24,0,0,1-5.55,1.16,13,13,0,0,0-13.54,0,4.25,4.25,0,0,1-5.56-1.16l-6.07-8.1a2,2,0,0,0-3.6,1.2v6a2,2,0,0,1,3.6-1.2l7.4,9.87a2,2,0,0,0,2.82.39,13,13,0,0,1,16.36,0,2,2,0,0,0,2.81-.39l7.41-9.87a2,2,0,0,1,3.6,1.2v-6A2,2,0,0,0,620.41,373.94Z"
      fill="#2f0034"
    />
    <path
      d="M88,423.13v-16h40v16h-8l-3.55-10.63a2,2,0,0,0-1.89-1.37H101.45a2,2,0,0,0-1.9,1.37L96,423.13Z"
      fill="#1f0023"
    />
    <path
      d="M138,323.13H78a2,2,0,0,0-2,2v8a2,2,0,0,0,2,2h0a2,2,0,0,1,2,2v68a2,2,0,0,0,2,2h52a2,2,0,0,0,2-2v-68a2,2,0,0,1,2-2h0a2,2,0,0,0,2-2v-8A2,2,0,0,0,138,323.13Z"
      fill="#1f0023"
    />
    <path
      d="M97.72,323.13l3.69-11.06a6.58,6.58,0,0,0,0-4.16l-3.69-11.06H124L119.63,310,124,323.13Z"
      fill="#1f0023"
    />
    <polygon
      points="104.73 244.28 97.72 296.85 124.01 296.85 117 244.28 104.73 244.28"
      fill="#2eaf69"
    />
    <path
      d="M117,244.27,115.63,234a3.29,3.29,0,0,0-3.26-2.85h-3A3.29,3.29,0,0,0,106.1,234l-1.37,10.29Z"
      fill="#52084d"
    />
    <path
      d="M104.29,296.85l1.2-4.77a4.11,4.11,0,0,1,5-3l.77.2a4.13,4.13,0,0,0,5-3h0a4.12,4.12,0,0,1,5.67-2.77l.33.15L124,296.85Z"
      fill="#79eae6"
    />
    <path
      d="M106.79,278.68l-1-1.47a3.85,3.85,0,0,1,.12-4.45l1.5-2a3.85,3.85,0,0,1,6.38.33l1.49,2.47a3.86,3.86,0,0,1-1.58,5.43l-2,1A3.85,3.85,0,0,1,106.79,278.68Z"
      fill="#79eae6"
    />
    <path
      d="M122.55,301.23h-5.61a4.37,4.37,0,0,0-4.15,3l-1.12,3.34a7.73,7.73,0,0,0,0,4.85l3.58,10.72H124l-3.57-10.72a7.6,7.6,0,0,1,0-4.85Z"
      fill="#52084d"
    />
    <path
      d="M74.81,255.11l1.53.38a4.63,4.63,0,0,1,3.37,3.37l.39,1.54a1,1,0,0,0,1.88,0l.39-1.54a4.63,4.63,0,0,1,3.37-3.37l1.53-.38a1,1,0,0,0,0-1.89l-1.53-.38a4.63,4.63,0,0,1-3.37-3.37L82,247.93a1,1,0,0,0-1.88,0l-.39,1.54a4.63,4.63,0,0,1-3.37,3.37l-1.53.38A1,1,0,0,0,74.81,255.11Z"
      fill="#c3017b"
    />
    <rect x="68.01" y="273.13" width="4" height="4" rx="1.28" fill="#79eae6" />
    <rect x="138.01" y="255.13" width="4" height="4" rx="1.28" fill="#79eae6" />
    <path
      d="M562.74,347.11l1.54.38a4.63,4.63,0,0,1,3.37,3.37l.38,1.54a1,1,0,0,0,1.89,0l.38-1.54a4.65,4.65,0,0,1,3.37-3.37l1.54-.38a1,1,0,0,0,0-1.89l-1.54-.38a4.65,4.65,0,0,1-3.37-3.37l-.38-1.54a1,1,0,0,0-1.89,0l-.38,1.54a4.63,4.63,0,0,1-3.37,3.37l-1.54.38A1,1,0,0,0,562.74,347.11Z"
      fill="#6a0058"
    />
    <rect x="500.01" y="341.13" width="4" height="4" rx="1.28" fill="#79eae6" />
    <rect x="516.01" y="337.13" width="4" height="4" rx="1.28" fill="#79eae6" />
    <rect x="324.01" y="173.13" width="4" height="4" rx="1.28" fill="#79eae6" />
    <rect x="312.01" y="153.13" width="4" height="4" rx="1.28" fill="#79eae6" />
    <rect x="300.01" y="179.13" width="4" height="4" rx="1.28" fill="#79eae6" />
    <rect x="306.01" y="165.13" width="6" height="6" rx="2.25" fill="#79eae6" />
    <rect x="328.01" y="183.13" width="6" height="6" rx="2.25" fill="#79eae6" />
    <rect x="526.01" y="347.13" width="6" height="6" rx="2.25" fill="#79eae6" />
    <path
      d="M263.25,232.29c-4.16-14.13-6.87-19.12-7.82-29.52l-1.2-13.26a2.62,2.62,0,0,0-2.61-2.38h0a2.6,2.6,0,0,0-2.33,1.45l-4,7.92a12,12,0,0,1-10.74,6.63H221.42a12,12,0,0,1-10.73-6.63l-4-7.92a2.61,2.61,0,0,0-2.34-1.45h0a2.61,2.61,0,0,0-2.6,2.38l-1.2,13.26c-1,10.4-3.67,15.39-7.82,29.52A65.22,65.22,0,0,0,190,251.07a60.69,60.69,0,0,0,8,30.06h60a60.69,60.69,0,0,0,8-30.06A65.92,65.92,0,0,0,263.25,232.29Z"
      fill="#2f0034"
    />
    <path
      d="M247,230.74,244.25,223a17.9,17.9,0,0,0-16.86-11.89H208a11.66,11.66,0,0,0-11.09,8.06c-1.16,3.58-2.55,7.68-4.16,13.16A65.22,65.22,0,0,0,190,251.07a60.69,60.69,0,0,0,8,30.06h44.55L248,262a50.79,50.79,0,0,0,2-14h0A51.11,51.11,0,0,0,247,230.74Z"
      fill="#1f0023"
    />
    <path d="M182,251.07v0Z" fill="#1f0023" />
    <path d="M254,251v0Z" fill="#1f0023" />
    <path
      d="M264.5,237.13,277,252.36a4.12,4.12,0,0,1,.32,4.95h0a4,4,0,0,1-5.42,1.24l-14.18-6.71a10,10,0,0,1-5.72-9v-5.67Z"
      fill="#2f0034"
    />
    <path
      d="M260,252.84a60.69,60.69,0,0,1-8,28.29h6a60.65,60.65,0,0,0,7.82-25.52Z"
      fill="#6a0058"
    />
    <path
      d="M257.26,232.33a6.66,6.66,0,0,0,6.33,4.74h.91c-.36-1.64-.77-3.24-1.25-4.78-4.16-14.13-6.87-19.12-7.82-29.52l-1.2-13.26a2.62,2.62,0,0,0-2.61-2.38h0a2.61,2.61,0,0,0-2.32,1.42,7.64,7.64,0,0,0-.79,4.12c.29,3.21.73,8,.92,10.1.95,10.4,3.66,15.39,7.82,29.52Z"
      fill="#6a0058"
    />
    <path
      d="M204.69,196.5a12,12,0,0,0,10.73,6.63h6a12,12,0,0,1-10.73-6.63l-4-7.92a2.61,2.61,0,0,0-2.34-1.45h0a2.61,2.61,0,0,0-2.6,2.38l-.09,1Z"
      fill="#6a0058"
    />
    <path
      d="M542.81,289.71l-3.75,9a4,4,0,0,1-3.78,2.46L518,300.85V311l17.52-.12a10.11,10.11,0,0,0,9.71-7.14l2.11-6.76a3,3,0,0,1,2.86-2.1h5.41a2.39,2.39,0,0,0,2.39-2.39h0a2.39,2.39,0,0,0-1.5-2.21l-7.67-3.07a4.67,4.67,0,0,0-1.73-.33h0A4.67,4.67,0,0,0,542.81,289.71Z"
      fill="#2f0034"
    />
    <path
      d="M471.2,289.71l3.76,9a4,4,0,0,0,3.77,2.46l17.28-.35v10l-17.53.28a10.37,10.37,0,0,1-9.7-7.42L466.67,297a3,3,0,0,0-2.87-2.1h-5.41a2.39,2.39,0,0,1-2.38-2.39h0a2.38,2.38,0,0,1,1.5-2.21l7.67-3.07a4.61,4.61,0,0,1,1.72-.33h0A4.66,4.66,0,0,1,471.2,289.71Z"
      fill="#1f0023"
    />
    <path
      d="M525.23,313.83l4.8-7.44c5.19-8,8-15.33,8-25.26,0-10.84-10-24-31-24s-31,13.16-31,24c0,9.93,2.79,17.24,8,25.26l4.8,7.44a5.94,5.94,0,0,1-.89,7.47L482,327.13c4.83,2.72,10.36,2.11,15-1l2.26-1.52L501,327.5a6.82,6.82,0,0,0,12,0l1.73-2.91,2.26,1.52c4.62,3.13,10.16,3.74,15,1l-5.89-5.83A5.94,5.94,0,0,1,525.23,313.83Z"
      fill="#1f0023"
    />
    <path
      d="M525.23,313.83l4.8-7.44c5.19-8,8-15.33,8-25.26,0-10.84-10-24-31-24-10.71,0-18.55,3.43-23.67,8.22A40.12,40.12,0,0,1,497,263.13c21,0,29,13.16,29,24,0,9.93-6,24.64-10,32-.86,1.58-1.77,3.81-1.25,5.46l2.26,1.52c4.62,3.13,10.16,3.74,15,1l-5.89-5.83A5.94,5.94,0,0,1,525.23,313.83Z"
      fill="#2f0034"
    />
    <path
      d="M482,327.13a20,20,0,0,0,16-8A22.13,22.13,0,0,0,503.4,326a5.56,5.56,0,0,0,7.21,0,22.16,22.16,0,0,0,5.4-6.91,20,20,0,0,0,16,8h0a3.17,3.17,0,0,1-2.07,3c-4.29,1.57-8.94.64-12.92-2.13l-1.17-.81a21.94,21.94,0,0,1-6.48,7.62,4,4,0,0,1-4.73,0,21.94,21.94,0,0,1-6.48-7.62L497,328c-4,2.77-8.63,3.7-12.93,2.13a3.16,3.16,0,0,1-2.06-3Z"
      fill="#2898b2"
    />
    <path
      d="M532,283.41a40,40,0,0,1-2.17,13.17,3.44,3.44,0,0,0,3.27,4.55h0a41.13,41.13,0,0,0,4.9-20c0-10.84-10-24-31-24-8.68,0-15.48,2.25-20.47,5.66A38.36,38.36,0,0,1,503,259.41C524,259.41,532,272.56,532,283.41Z"
      fill="#6a0058"
    />
    <path
      d="M334,216.56l-4.8-7.64a6.23,6.23,0,0,1,.89-7.68l5.89-6.11c-4.83-2.79-10.37-2-15,1.16l-2.26,1.57-1.73-3a6.73,6.73,0,0,0-12,0l-1.72,3L301,196.29c-4.63-3.21-10.16-3.95-15-1.16l5.89,6.11a6.23,6.23,0,0,1,.89,7.68l-4.8,7.64c-5.19,8.25-10,16.37-10,26.57h0c0,16.48,12.37,22,22,22h22c9.63,0,22-5.52,22-22h0C344,232.93,339.22,224.81,334,216.56Z"
      fill="#1f0023"
    />
    <polygon
      points="320.01 253.13 302.01 253.13 296.01 271.13 326.01 271.13 320.01 253.13"
      fill="#1f0023"
    />
    <path
      d="M334,216.56l-4.8-7.64a6.23,6.23,0,0,1,.89-7.68l5.89-6.11a6.53,6.53,0,0,0-1.6-.64,9.45,9.45,0,0,0-1.57-.3,10.17,10.17,0,0,0-9,4.05l-4.33,5.6a4.41,4.41,0,0,0-.25,5l0,0,4.8,7.64c5.19,8.25,10,16.37,10,26.57,0,11-4,22-12,22,9.63,0,22-5.52,22-22C344,232.93,339.22,224.81,334,216.56Z"
      fill="#2f0034"
    />
    <path
      d="M329.24,202.34a5.19,5.19,0,0,1,.88-1.1l4.59-4.76c-4.5-2-9.48-1.12-13.69,1.81l-2.26,1.57-1.73-3a6.73,6.73,0,0,0-12,0l-1.72,3L301,198.29c-4.22-2.93-9.19-3.79-13.69-1.81l4.59,4.76a5.48,5.48,0,0,1,.87,1.1,20,20,0,0,1,9.24,6.79l5.41-2.7a8,8,0,0,1,7.18,0l5.41,2.7A19.92,19.92,0,0,1,329.24,202.34Z"
      fill="#2f0034"
    />
    <path
      d="M328.49,207.13a8.61,8.61,0,0,0,.05,8.6l.49.83c5.19,8.25,10,16.37,10,26.57,0,12.8-7.19,19.8-15,21.92h0c9.27-.71,20-6.55,20-21.92,0-10.2-4.79-18.32-10-26.57l-4.8-7.64a6.22,6.22,0,0,1-.74-1.79Z"
      fill="#6a0058"
    />
    <path
      d="M321,196.29l-1.17.81a22.07,22.07,0,0,0-5.24-6.63,5.53,5.53,0,0,0-7.21,0,22.07,22.07,0,0,0-5.24,6.63l-1.16-.81c-4.63-3.21-10.16-3.95-15-1.16a20,20,0,0,1,16,8l5.41-2.7a8,8,0,0,1,7.18,0l5.41,2.7a20,20,0,0,1,16-8C331.18,192.34,325.64,193.08,321,196.29Z"
      fill="#2898b2"
    />
    <path
      d="M314.6,200.43l5.41,2.7h0a22.16,22.16,0,0,0-5.4-6.91h0a5.53,5.53,0,0,0-7.21,0h0a22.13,22.13,0,0,0-5.39,6.91h0l5.41-2.7a8,8,0,0,1,3.59-.85h0A8,8,0,0,1,314.6,200.43Z"
      fill="#79eae6"
    />
    <path
      d="M324,265.05c-.66.05-1.32.08-2,.08h-5.23a2,2,0,0,0-1.89,2.63l1.12,3.37h10Z"
      fill="#2f0034"
    />
    <path
      d="M365.53,203.2c5.71-1.14,14,12.88,16.3,22.13,1.06,4.29-2.79,8.37-8.5,9.51s-11.12-1.1-12.18-5.38C358.88,220.2,359.82,204.34,365.53,203.2Z"
      fill="#2f0034"
    />
    <path
      d="M440,255.13c0,33.14-18.81,76-42,76s-42-42.86-42-76c0-10.56,1.91-18.86,5.26-25.26,7.17-13.7,20.94-18.49,36.74-18.49C421.2,211.38,440,222,440,255.13Z"
      fill="#2f0034"
    />
    <path
      d="M386,225.13c-9.62,0-17.66.19-24.74,4.74C357.71,236,356,245.35,356,255.13c0,33.14,18.8,76,42,76a19,19,0,0,0,3.19-.27c6.15-1.05,7.93-5.18,10.81-10.72,7.78-14.94,12-34.22,12-51C424,236,409.2,225.13,386,225.13Z"
      fill="#1f0023"
    />
    <path
      d="M398,211.13a51.7,51.7,0,0,0-20.35,3.7c-3.55-6.64-8.39-12.38-12.13-11.63a3.87,3.87,0,0,0-2,1.18c3.07,2.14,7.14,8.05,9.61,13a2.71,2.71,0,0,0,1.37,1.3h0a2.71,2.71,0,0,0,1.9.09c5.06-1.57,11.23-3.6,17.08-3.6,20.1,0,40.54,10.86,40.54,44,0,25.42-11.07,56.56-26.69,69.69C426,319.49,440,283.7,440,255.13,440,222,421.2,211.13,398,211.13Z"
      fill="#6a0058"
    />
    <path
      d="M430.48,203.2a5.32,5.32,0,0,0-4,1.18c2.93,3.06,3.91,11.22,3.36,18.49a40.58,40.58,0,0,1-1,6.59,6.1,6.1,0,0,1-1.24,2.43c.53.89,1,1.81,1.52,2.77,2.73-.77,4.83-2.44,5.59-4.8a2.67,2.67,0,0,0,.13-.4C437.14,220.2,436.19,204.34,430.48,203.2Z"
      fill="#6a0058"
    />
    <path
      d="M426.5,204.38c-5,3.51-10.53,13.69-12.32,21-.73,3,.91,5.86,3.89,7.72a10,10,0,0,0,7.49,1.21c1.6-.36,2.39-1.11,3.3-4.8C430.84,221.44,430.38,208.45,426.5,204.38Z"
      fill="#2f0034"
    />
    <path
      d="M430.31,281a21.38,21.38,0,0,0-18-9.91H350a10,10,0,0,0-8.8,5.25,1.37,1.37,0,0,1-2.4,0,10,10,0,0,0-8.8-5.25H262a10,10,0,0,0-8.8,5.25,1.37,1.37,0,0,1-2.4,0,10,10,0,0,0-8.8-5.25H175.39A11.38,11.38,0,0,0,164,282.52v31.23a1.38,1.38,0,0,0,1.38,1.38h284.1a1.38,1.38,0,0,0,1.17-2.12Z"
      fill="#2f0034"
    />
    <path
      d="M346,275.13h63a20,20,0,0,1,16.87,9.26l19.56,30.74H452L429.9,280.39A20,20,0,0,0,413,271.13H350a10,10,0,0,0-8.78,5.22A9.9,9.9,0,0,1,346,275.13Z"
      fill="#52084d"
    />
    <path
      d="M258,275.13h76a9.9,9.9,0,0,1,4.78,1.22,10,10,0,0,0-8.78-5.22H262a10,10,0,0,0-8.78,5.22A9.9,9.9,0,0,1,258,275.13Z"
      fill="#52084d"
    />
    <path
      d="M408,283.13H154a10,10,0,0,0-10,10V407.82a7.31,7.31,0,0,0,7.31,7.31H408Z"
      fill="#1f0023"
    />
    <path
      d="M488,351.13h26a6,6,0,0,1,6,6v11.92a6,6,0,0,1-6,6H488a0,0,0,0,1,0,0v-24A0,0,0,0,1,488,351.13Z"
      fill="#2f0034"
    />
    <path
      d="M170,275.13h76a9.9,9.9,0,0,1,4.78,1.22,10,10,0,0,0-8.78-5.22H174a10,10,0,0,0-8.78,5.22A9.9,9.9,0,0,1,170,275.13Z"
      fill="#52084d"
    />
    <path
      d="M514,351.13H488v4h25a3,3,0,0,1,3,3v16.63a6,6,0,0,0,4-5.67V357.17A6,6,0,0,0,514,351.13Z"
      fill="#52084d"
    />
    <path
      d="M396,405.13v-112a10,10,0,0,1,10-10h0a18.06,18.06,0,0,1,15.3,8.48l10.13,16.21a15.55,15.55,0,0,0,13.19,7.31H476a20,20,0,0,1,20,20v30a10,10,0,0,0,10,10h12a10,10,0,0,1,10,10v20a10,10,0,0,1-10,10H406A10,10,0,0,1,396,405.13Z"
      fill="#2f0034"
    />
    <path
      d="M518,375.13H506a10,10,0,0,1-10-10v-30a20,20,0,0,0-20-20H444.63a15.55,15.55,0,0,1-13.19-7.31l-10.13-16.21a18.06,18.06,0,0,0-15.3-8.48c-.2,0-.39,0-.58,0l7.14,11.36c.26.35.51.71.74,1.08l9.23,14.78h0l.89,1.43a15.55,15.55,0,0,0,13.19,7.31H468a20,20,0,0,1,20,20v30a10,10,0,0,0,10,10h12a10,10,0,0,1,10,10v20a9.92,9.92,0,0,1-2,6h0a10,10,0,0,0,10-10v-20A10,10,0,0,0,518,375.13Z"
      fill="#52084d"
    />
    <polygon
      points="412.01 423.13 416.01 415.13 376.01 415.13 380.01 423.13 412.01 423.13"
      fill="#1f0023"
    />
    <path
      d="M400,419.86v3.27h12l4-8H404.74A4.73,4.73,0,0,0,400,419.86Z"
      fill="#52084d"
    />
    <polygon
      points="514.01 423.13 518.01 415.13 478.01 415.13 482.01 423.13 514.01 423.13"
      fill="#1f0023"
    />
    <path
      d="M502,419.86v3.27h12l4-8H506.74A4.73,4.73,0,0,0,502,419.86Z"
      fill="#52084d"
    />
    <polygon
      points="192.01 423.13 196.01 415.13 156.01 415.13 160.01 423.13 192.01 423.13"
      fill="#1f0023"
    />
    <path
      d="M180,419.86v3.27h12l4-8H184.74A4.73,4.73,0,0,0,180,419.86Z"
      fill="#52084d"
    />
    <polygon
      points="294.01 423.13 298.01 415.13 258.01 415.13 262.01 423.13 294.01 423.13"
      fill="#1f0023"
    />
    <path
      d="M282,419.86v3.27h12l4-8H286.74A4.73,4.73,0,0,0,282,419.86Z"
      fill="#52084d"
    />
  </svg>
);
