import { motion } from "framer-motion";
import styled from "styled-components";

const Container = styled(motion.div)`
  position: relative;
  width: fit-content;
  border-radius: 50px;
  box-sizing: border-box;
  border: 2px solid #fe0099;
  cursor: pointer;

  &:hover {
    button {
      transform: none;
    }
  }
`;

const Button = styled.button`
  background-color: #fe0099;
  width: 100%;
  height: 100%;
  border-radius: 50px;
  outline: none;
  border: none;
  padding: 15px 45px;
  box-sizing: border-box;
  transform: translateX(-5px) translateY(-5px);
  font-family: "Whitney", "Inter";
  font-size: 14pt;
  font-weight: 600;
  color: #fff;
  transition: transform 0.1s ease;
  cursor: pointer;
`;

const CuteButton = ({ text, ...props }: { text: string }) => {
  return (
    <Container whileTap={{ scale: 0.95 }} {...props}>
      <Button>{text}</Button>
    </Container>
  );
};

export default CuteButton;
