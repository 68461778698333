import { Helmet } from "react-helmet-async";
import styled from "styled-components";

import GigglMac from "../assets/images/gigglimac.png";

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  padding-top: 90px;
  max-width: 65ch;

  h1,
  h2,
  h3,
  p {
    margin-bottom: 20px;
  }

  p {
    color: ${({ theme }) => theme.textSecondary};
    font-size: 1.2rem;
  }

  a {
    color: ${({ theme }) => theme.brandPrimary};

    &:hover {
      text-decoration: underline;
    }
  }

  i {
    color: ${({ theme }) => theme.textTertiary};
  }

  ul {
    font-size: 1.2rem;
    color: ${({ theme }) => theme.textSecondary};
  }
`;

const HeaderImage = styled.img`
  object-fit: contain;
  height: 400px;
  margin-bottom: 45px;
  border-radius: 20px;
`;

const Jobs = () => (
  <Wrapper>
    <Helmet>
      <title>Jobs</title>
    </Helmet>
    <HeaderImage draggable={false} src={GigglMac} />
    <i>Come join us...</i>
    <h1>We're Reinventing Online Interaction</h1>

    <p>
      We're a mission focused team dedicated to bringing the feeling of
      togetherness online. Online collaboration has hit a brick wall, with
      products stagnating at "shared" collaboration. We've taken on a different
      approach - <b>multiplayer collaboration</b>.
    </p>

    <p>
      At Giggl's core, we believe that we can truly bring people together in a
      unique way, and the team is massively passionate about this. In April
      2020, when we were placed in a national lockdown, we couldn't see each
      other, and with all the available options at the time just not cutting it,
      we decided to take matters into our own hands and create a solution that
      was a bit outside the box.
    </p>

    <br />

    <h2>Perks</h2>
    <ul>
      <li>Remote or in-office (London)</li>
      <li>$5,000 desk fund</li>
      <li>Commute & lunch reimbursements</li>
      <li>Health, dental and vision insurance (US)</li>
      <li>5 weeks/yr PTO</li>
      <li>Open development team (choose what to work on)</li>
    </ul>

    <br />
    <br />

    <h2>Open Positions</h2>
    <p>None yet... check back later!</p>
  </Wrapper>
);

export default Jobs;
