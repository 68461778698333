import styled from "styled-components";

import NanoNews from "../assets/images/nanonews.svg";
import { THEME } from "../utils/Theme";

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  padding-top: 90px;
  max-width: 65ch;

  h1,
  h2,
  h3,
  p {
    margin-bottom: 20px;
  }

  p {
    color: ${({ theme }) => theme.textSecondary};
    font-size: 1.2rem;
  }

  a {
    color: ${({ theme }) => theme.brandPrimary};

    &:hover {
      text-decoration: underline;
    }
  }

  i {
    color: ${THEME.textTertiary};
  }

  ul {
    font-size: 1.2rem;
    color: ${({ theme }) => theme.textSecondary};
  }

  img:last-child {
    width: 100%;
  }
`;

const HeaderImage = styled.img`
  object-fit: contain;
  height: 200px;
  margin-bottom: 45px;
  border-radius: 20px;
`;

const Seed = () => (
  <Wrapper>
    <HeaderImage draggable={false} src={NanoNews} />
    <i>July 10th, 2021</i>
    <h1>
      Giggl Raises $2.2M to Build the Future of Online Collaboration through
      Multiplayer Browsing
    </h1>

    <p>
      Collaboration tools are more important now than ever. People are
      struggling to keep up with the rate of change in the world around us, but
      online collaboration has hit a brick wall - the effects of this issue were
      demonstrated on the influx of people working and studying from home.
      Today's array of collaboration tools aren't working because of the lack of
      real-time visibility and human touch - all you get is a digital version of
      your input. Even though collaboration is fundamental, the tools we use
      prevent us from working the way that we want.
    </p>

    <p>
      We have raised $2.2 million in a seed round lead by Craft Ventures, with
      additional capital deployed from LAUNCH (under Jason Calacanis) and Crush
      Ventures, alongside angel investors; Guillermo Rauch (CEO of Vercel) and
      Darian Shirazi (ex Facebook and Radius).
    </p>

    <p>
      This money will be used to build our own custom server infrastructure,
      dedicated to Giggl's service, minimizing downtime and reducing costs,
      alongside expanding the team up to ten, bringing on extra developers,
      support staff and a designer. We plan to turn from a "product into a
      platform" as we plan to expand into every type of online collaboration.
    </p>

    <p>
      Our aim is to make being together online just as good as being together
      IRL. We hope you'll be on this journey with us. :)
    </p>
  </Wrapper>
);

export default Seed;
