import { motion } from "framer-motion";
import { useCallback, useState } from "react";
import styled from "styled-components";
import { THEME } from "../utils/Theme";
import Button from "./Button";
import { USFlagIcon } from "./Icons";

const Container = styled.div`
  width: 100%;
  border-radius: 10px;
  box-shadow: rgb(0 0 0 / 44%) 0px 4px 24px;
  overflow: hidden;
  font-family: "Whitney", "Inter";
  background-color: ${THEME.bgSecondary};
  user-select: none;
`;

const HeaderContainer = styled.div`
  border-bottom: 1px solid #41004d;
  display: flex;
  flex-direction: column;
  width: 100%;
  flex-shrink: 0;
  padding: 20px 20px;
  box-sizing: border-box;
`;

const Header = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;

  p {
    font-weight: 600;
    font-size: 20px;
    margin: 0;
  }
`;

const Content = styled.div`
  padding: 25px;
  box-sizing: border-box;

  p {
    color: ${THEME.textTertiary};
    font-size: 11pt;
  }
`;

const PortalOptionRow = styled.div`
  display: flex;
  flex-direction: row;
  height: 45px;
  align-items: center;

  :not(:last-child) {
    margin-bottom: 60px;
  }

  @media (max-width: 1000px) {
    flex-wrap: wrap;
  }
`;

const OptionInfoColumn = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
`;

const TypeContainer = styled.div`
  display: flex;
  flex-direction: column;
  background-color: ${THEME.bgPrimary};
  border: 1px solid #41004d;
  margin-left: auto;
  width: 175px;
  height: 40px;
  border-radius: 5px;
  cursor: pointer;
  align-items: center;
  justify-content: center;
  box-sizing: border-box;

  @media (max-width: 650px) {
    margin-top: 10px;
    width: 100%;
  }
`;

const RegionSelector = styled(TypeContainer)`
  flex-direction: row;
  justify-content: flex-start;
  padding: 10px;

  svg {
    width: 25px;
    margin-right: 10px;
  }
`;

const Switches = styled(TypeContainer)`
  position: relative;
  flex-direction: row;
  border-radius: 40px;
`;

const SwitchBackdrop = styled(motion.div)`
  position: absolute;
  left: 0;
  width: 50%;
  height: 100%;
  background: ${THEME.brandPrimary};
  border-radius: 50px;
`;

const SwitchContainer = styled.div<ISwitchProps>`
  display: flex;
  flex-direction: row;
  height: 100%;
  width: 50%;
  border-radius: 50px;
  z-index: 1;
  /* background: ${(props) =>
    props.selected === true
      ? "linear-gradient(102deg,#4551b5 9.16%,#5643cc 32.02%,#543fd7 64.72%)"
      : "rgb(17, 19, 20)"}; */
  /* color: ${(props) =>
    props.selected === true ? "#fff" : "rgb(215, 216, 219)"}; */
  align-items: center;
  justify-content: center;

  &:hover {
    opacity: 0.8;
  }
`;

const Footer = styled.div`
  display: flex;
  align-items: center;
  padding: 20px 25px;
  box-sizing: border-box;
  border-top: 1px solid #41004d;

  button {
    margin-left: auto;
  }
`;
interface ISwitchProps {
  selected: boolean;
}

const FakeCreatePortalModal = () => {
  const [type, setType] = useState(1);

  const createPortal = useCallback(() => {
    window.location.href =
      type == 2 ? "https://giggl.to/giggl.app" : "https://canary.giggl.app";
  }, [type]);

  return (
    <Container>
      <HeaderContainer>
        <Header>
          <p>Create a Portal</p>
        </Header>
      </HeaderContainer>

      <Content>
        <PortalOptionRow>
          <OptionInfoColumn>
            <h4>Privacy</h4>
            <p>Public portals display on the home page</p>
          </OptionInfoColumn>
          <Switches>
            <SwitchBackdrop animate={{ x: type === 1 ? 0 : "100%" }} />
            <SwitchContainer
              selected={type === 1}
              onClick={() => {
                setType(1);
              }}
            >
              Public
            </SwitchContainer>
            <SwitchContainer
              selected={type === 2}
              onClick={() => {
                setType(2);
              }}
            >
              Private
            </SwitchContainer>
          </Switches>
        </PortalOptionRow>
        <PortalOptionRow>
          <OptionInfoColumn>
            <h4>Region</h4>
            <p>You can choose the region nearest to you</p>
          </OptionInfoColumn>
          <RegionSelector>
            <USFlagIcon />
            US East
          </RegionSelector>
        </PortalOptionRow>
      </Content>
      <Footer>
        <Button onClick={createPortal} brand>
          Create Portal
        </Button>
      </Footer>
    </Container>
  );
};

export default FakeCreatePortalModal;
