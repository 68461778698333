import styled, { keyframes } from "styled-components";

const spin = keyframes`
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }

  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
`;

const CircleLoader = styled.div`
  width: 8.8px;
  height: 8.8px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: auto;
  border-radius: 50%;
  border-top: 4px solid transparent;
  -webkit-transform: translateZ(0);
  -ms-transform: translateZ(0);
  transform: translateZ(0);
  -webkit-animation: ${spin} 1s infinite linear;
  animation: ${spin} 1s infinite linear;
`;

export default CircleLoader;
