import { Helmet } from "react-helmet-async";
import styled from "styled-components";

import { NetworkingLogo } from "../components/BrandVectors";
import { THEME } from "../utils/Theme";

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  padding-top: 90px;
  max-width: 65ch;
  align-items: center;
  text-align: center;

  h1,
  h2,
  h3,
  p {
    margin: 20px 0px;
  }

  p {
    color: ${({ theme }) => theme.textSecondary};
    font-size: 1.2rem;
  }

  code {
    font-size: 1rem;
  }

  a {
    color: ${({ theme }) => theme.brandPrimary};

    &:hover {
      text-decoration: underline;
    }
  }

  i {
    color: ${THEME.textTertiary};
  }

  ul {
    font-size: 1.2rem;
    color: ${({ theme }) => theme.textSecondary};
    list-style-type: none;
  }

  img:last-child {
    width: 100%;
  }
`;

const HeaderImage = styled(NetworkingLogo)`
  max-width: 300px;
  margin-bottom: 45px;
`;

const ISP = () => (
  <Wrapper>
    <Helmet>
      <title>ISP</title>
    </Helmet>
    <HeaderImage />
    <h1>Giggl ISP (AS399531)</h1>
    <p>
      Giggl operates as an ISP under the Autonomous System Number{" "}
      <code>AS399531</code>.
    </p>
    <h2>Why?</h2>
    <p>
      Multiple reasons. First one being that since we run the portals within our
      own infrastructure, we need a clean and defined way to route out to the
      public internet - cloud & default provider IP-space is marked as spammy &
      high risk. With our own network, we can control and build network
      relationships with high-ticket platforms that you use on Giggl, like
      Google, Discord and Netflix.
    </p>
    <p>
      Giggl also operates it's own co-located infrastructure, so we need a way
      to control our routing policy within our colocation facilities. We have a
      lot of outgoing video traffic and need to make sure that it gets delivered
      in a stable way.
    </p>
    <h2>What are Giggl's IP blocks?</h2>
    <p>We currently announce two /24 IPv4 blocks & one /36 IPv6 block:</p>
    <ul>
      <li>172.110.130.0/24 (US)</li>
      <li>162.218.156.0/24 (GB)</li>
      <li>2602:fc50::/36</li>
    </ul>

    <h2>How can I report abuse?</h2>
    <p>
      Please contact the email listed on our WHOIS: <code>phin@giggl.app</code>
    </p>

    <h2>Can I peer?</h2>
    <p>
      Giggl as an ISP is currently operating under a closed peering policy. As
      we grow, we will open it up to allow peering with other networks that
      believe that Giggl users frequently use their network or service on our
      platform, or large multi-homed networks that deliver video traffic.
    </p>

    <h2>Useful Links</h2>
    <a
      target={"_blank"}
      rel="noopener noreferrer"
      href={"https://www.peeringdb.com/net/26508"}
    >
      PeeringDB
    </a>
    <a
      target={"_blank"}
      rel="noopener noreferrer"
      href={"https://lg.giggl.app"}
    >
      Looking Glass
    </a>
  </Wrapper>
);

export default ISP;
