import React, { useContext } from "react";
import {
  BrowserRouter as Router,
  Redirect,
  Route,
  Switch,
} from "react-router-dom";
import { Helmet, HelmetProvider } from "react-helmet-async";
import styled, { ThemeProvider } from "styled-components";
import Footer from "./components/Footer";
import Nav from "./components/Nav";
import ScrollToTop from "./components/ScrollToTop";
import { GigglContext } from "./context";
import Home from "./pages/Home";
import Jobs from "./pages/Jobs";
import Privacy from "./pages/Privacy";
import Terms from "./pages/Terms";
import * as g from "@giggl/commons";
import Seed from "./pages/Seed";
import ISP from "./pages/ISP";
// trigger build
const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 100vh;
`;

const Content = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 60px;
  width: 75%;
  max-width: 1200px;

  @media (max-width: 1300px) {
    width: 80%;
  }
`;

function App() {
  const { theme } = useContext(GigglContext);

  return (
    <HelmetProvider>
      <Helmet
        defaultTitle={"Giggl - Watch & Browse the Web, Together"}
        titleTemplate={"%s • Giggl"}
      ></Helmet>
      <ThemeProvider theme={g.appThemes[theme]}>
        <Wrapper>
          <Router>
            <ScrollToTop />
            <Nav />
            <Content>
              <Switch>
                <Route exact path={"/"} component={Home} />
                <Route exact path={"/terms"} component={Terms} />
                <Route exact path={"/privacy"} component={Privacy} />
                <Route exact path={"/jobs"} component={Jobs} />
                <Route exact path={"/isp"} component={ISP} />
                <Route exact path={"/seed"} component={Seed} />
                <Route
                  exact
                  path={"/investors"}
                  component={() => {
                    window.location.href =
                      "https://www.notion.so/gigglinvestors/Giggl-for-Investors-049022e06fe148beb1c47a2fb0549b5a";
                    return null;
                  }}
                />
                <Route component={() => <Redirect to={""} />} />
              </Switch>
            </Content>
            <Footer />
          </Router>
        </Wrapper>
      </ThemeProvider>
    </HelmetProvider>
  );
}

export default App;
