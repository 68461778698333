import { AppThemeNames } from "@giggl/commons";
import React, { Dispatch, SetStateAction, useState } from "react";

interface IGigglContext {
  theme: AppThemeNames;
  setTheme: Dispatch<SetStateAction<AppThemeNames>> | null;
}

export const GigglContext = React.createContext({
  theme: "default",
  setTheme: null,
} as IGigglContext);

export const GigglProvider = ({ children }: { children: React.ReactNode }) => {
  const [theme, setTheme] = useState<AppThemeNames>("default");

  return (
    <GigglContext.Provider
      value={{
        theme,
        setTheme,
      }}
    >
      {children}
    </GigglContext.Provider>
  );
};
