import styled from "styled-components";

import PhinIcon from "../assets/images/avatars/phin.png";
import RossIcon from "../assets/images/avatars/ross.jpg";
import OliIcon from "../assets/images/avatars/oli.jpg";
import RobIcon from "../assets/images/avatars/rob.jpeg";
import { MouseIcon } from "./Icons";
import { AnimateSharedLayout, motion } from "framer-motion";
import { useEffect, useState } from "react";

const Wrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-evenly;
`;

const AvatarWrapper = styled.div`
  position: relative;
  width: 80px;
  height: 80px;

  @media (max-width: 1100px) {
    width: 60px;
    height: 60px;
  }
`;

const Avatar = styled.img`
  width: 100%;
  height: 100%;
  border-radius: 50%;
`;

const IndicatorContainer = styled(motion.div)`
  display: flex;
  background-color: white;
  border-radius: 20px;
  position: absolute;
  top: -6px;
  right: -6px;
  height: 20px;
  width: 20px;
  align-items: center;
  border: 4px solid #191d1f;
  padding: 5px;
  align-content: center;
  justify-content: center;

  img {
    height: 20px;
    width: 20px;
  }
  svg {
    height: 20px;
    width: 20px;
    color: #000;
  }
`;

const users = [
  {
    name: "phin",
    icon: PhinIcon,
  },
  {
    name: "oli",
    icon: OliIcon,
  },
  {
    name: "rob",
    icon: RobIcon,
  },
  {
    name: "ross",
    icon: RossIcon,
  },
];

const FakePortalUserList = () => {
  const [userInControl, setUserInControl] = useState(0);

  useEffect(() => {
    const changeControl = () => {
      setUserInControl((s) => (s === users.length - 1 ? 0 : s + 1));
    };

    const interval = setInterval(changeControl, 800);

    return () => {
      clearInterval(interval);
    };
  }, []);

  return (
    <Wrapper>
      <AnimateSharedLayout>
        {users.map((user, idx) => (
          <AvatarWrapper key={idx}>
            <Avatar src={user.icon} />
            {userInControl === idx ? (
              <IndicatorContainer layout layoutId={"controller"}>
                <MouseIcon />
              </IndicatorContainer>
            ) : null}
          </AvatarWrapper>
        ))}
      </AnimateSharedLayout>
    </Wrapper>
  );
};

export default FakePortalUserList;
