import { Component, MouseEventHandler } from "react";
import styled from "styled-components";
import CircleLoader from "./CircleLoader";

const StyledButton = styled.button<{
  flat?: boolean;
  success?: boolean;
  danger?: boolean;
  brand?: boolean;
}>`
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 40px;
  width: fit-content;
  padding: 10px 15px;
  border: none;
  border-radius: 5px;
  outline: none;
  font-size: 1rem;
  font-family: "Whitney", "Inter";
  font-weight: 600;
  color: ${({ theme }) => theme.textPrimary};
  position: relative;
  background: ${({ flat, success, danger, brand }) =>
    !flat
      ? danger
        ? "red"
        : brand
        ? "#fe0099"
        : success
        ? "lightgreen"
        : "linear-gradient(to right,#af2fa6,rgba(232,55,144))"
      : "transparent"};
  opacity: ${(props) => (props.disabled ? 0.5 : 1)};
  transition: all 0.2s;
  cursor: ${({ disabled }) => (disabled ? "default" : "pointer")};
  svg,
  image {
    margin-right: 5px;
  }
  &:hover {
    filter: brightness(0.75);
  }
  div {
    margin: 0;
  }
`;

export default class Button extends Component<
  {
    triggerOnEnter?: boolean;
    loading?: boolean;
    disabled?: boolean;
    onClick?: MouseEventHandler<HTMLButtonElement>;
    brand?: boolean;
  },
  { btn: HTMLButtonElement }
> {
  btn: HTMLButtonElement | null | undefined;

  componentDidMount() {
    if (!this.props.triggerOnEnter) return;

    document.addEventListener("keydown", this._handleKeyDown, false);
  }

  componentWillUnmount() {
    if (!this.props.triggerOnEnter) return;

    document.removeEventListener("keydown", this._handleKeyDown, false);
  }

  _handleKeyDown = ({ key }: KeyboardEvent) => {
    if (key !== "Enter" || !this.btn) return;

    this.btn.click();
  };

  render() {
    const props = this.props;
    return (
      <StyledButton
        ref={(btn) => (this.btn = btn)}
        disabled={props.loading || props.disabled}
        {...props}
      >
        {props.loading ? <CircleLoader /> : props.children}
      </StyledButton>
    );
  }
}
