import { useEffect, useState } from "react";
import styled, { keyframes } from "styled-components";
import Typed from "typed.js";

import GigglStep1 from "../assets/images/gigglstep1.png";
import GigglStep2 from "../assets/images/gigglstep2.png";
import GigglStep3 from "../assets/images/gigglstep3.png";
import GigglStep4 from "../assets/images/communication.png";
import CuteButton from "../components/CuteButton";
import FakeCreatePortalModal from "../components/FakeCreatePortalModal";
import FakeGigglClient from "../components/FakeGigglClient";
import FakePortalUserList from "../components/FakePortalUserList";
import PortalReel from "../components/PortalReel";
import { THEME } from "../utils/Theme";
import { Hero } from "../components/BrandVectors";

const textFadeDown = keyframes`
  from {
    opacity: 0.3;
    transform: translateY(-10px);
  }
`;

const Wrapper = styled.div`
  position: relative;
  width: 100%;
  padding-top: 90px;
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  flex-shrink: 1;
`;

const HeroBackdrop = styled(Hero)`
  position: absolute;
  /* top: 0; */
  align-self: center;
  width: 100vw;
  /* margin-top: 90px; */
  opacity: 0.25;
  z-index: -1;

  @media (max-width: 800px) {
    display: none;
  }
`;

// const AnnouncementCTA = styled.div`
//   display: flex;
//   flex-direction: row;
//   align-items: center;
//   padding: 10px 15px;
//   box-sizing: border-box;
//   background: ${THEME.brandGradient};
//   border-radius: 50px;
//   margin-bottom: 60px;

//   b,
//   a {
//     color: #fff;
//     margin-left: 5px;

//     &:hover {
//       text-decoration: underline;
//     }
//   }

//   @media (max-width: 650px) {
//     flex-direction: column;
//     border-radius: 10px;
//   }
// `;

const Title = styled.h1`
  font-weight: 900;
  text-align: center;
  color: ${THEME.textSecondary};
  font-size: 48pt;
  animation: ${textFadeDown} 1s ease;

  @media (max-width: 1100px) {
    font-size: 42pt;
  }
`;

const HighlightedText = styled.span`
  display: inline-block;
  background: linear-gradient(to right, #af2fa6, rgba(232, 55, 144));
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
`;

const Subtitle = styled.p`
  margin: 0;
  margin-top: 20px;
  color: ${THEME.textTertiary};
  font-size: 18pt;
  font-weight: 500;
  max-width: 600px;
`;

const Step = styled.img`
  width: 65px;
  height: 65px;
`;

const StyledCuteButton = styled(CuteButton)`
  margin-top: 40px;
`;

const GigglStepContainer = styled.div<{ rtl?: boolean }>`
  margin-top: 100px;
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 100%;
  flex-wrap: wrap;

  @media (max-width: 1100px) {
    flex-direction: ${({ rtl }) => (rtl ? "column-reverse" : "row")};
  }

  > div {
    display: flex;
    flex-direction: column;
    width: 50%;
    text-align: left;

    @media (max-width: 1100px) {
      width: 100%;
    }

    &:first-child {
      padding: ${({ rtl }) => (rtl ? "20px" : "0px")};
      box-sizing: border-box;
    }

    &:nth-child(2) {
      padding: ${({ rtl }) => (rtl ? "0px" : "20px")};
      box-sizing: border-box;
    }
  }

  #useCases {
    background: linear-gradient(to right, #af2fa6, rgba(232, 55, 144));
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
  }

  > div > h2 {
    display: inline-block;
  }

  > div > h2,
  > div > p {
    margin: 10px 0px 0px 0px;
  }

  > div > p {
    color: ${({ theme }) => theme.textSecondary};
    font-weight: 500;
    font-size: 1.2rem;
  }
`;

// const UserHover = styled.div`
//   display: flex;
//   align-items: center;
//   flex-direction: row;
//   position: absolute;
//   padding: 10px;
//   text-align: center;
//   bottom: 0.7em;
//   font-size: 14px;
//   margin: 0;
//   line-height: 1;
//   background-color: rgba(19, 19, 19);
//   border-radius: 10px;
//   animation: popup 0.2s ease-in-out;
// `;

const Section = styled.div<{ secondary?: boolean }>`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100vw;
  background-color: ${({ theme, secondary }) =>
    secondary ? theme.bgBlack : "transparent"};
  padding: 80px 12.5%;
  box-sizing: border-box;
`;

const SectionContent = styled.div<{ column?: boolean }>`
  display: flex;
  flex-direction: ${({ column }) => (column ? "column" : "row")};
  align-items: center;
  max-width: 1200px;

  > div {
    box-sizing: border-box;
  }
  @media (max-width: 1200px) {
    flex-wrap: wrap;

    > div > img {
      max-width: 100%;
    }
  }
`;

const SectionColumn = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  text-align: left;
  padding: 20px;

  p {
    margin-top: 10px;
    font-size: 1.2rem;
    color: ${({ theme }) => theme.textSecondary};
  }
`;

const FinalCTASectionContent = styled(SectionContent)`
  margin-bottom: -90px;
  p,
  h1,
  div {
    margin: 15px 0px;
  }

  p {
    color: ${({ theme }) => theme.textSecondary};
    font-size: 1.2rem;
  }
`;

const CommsImage = styled.img`
  border-radius: 20px;
  max-width: 750px;
  /* min-width: 300px; */
  width: 100%;
`;

const typedStrings = [
  "Watch movies",
  "Watch TikTok",
  "Watch YouTube",
  "Learn",
  "Browse through Reddit",
  "Browse through Twitter",
];

const Home = () => {
  const [stringIndex, setStringIndex] = useState(-1);

  useEffect(() => {
    new Typed("#useCases", {
      strings: typedStrings,
      typeSpeed: 80,
      backSpeed: 40,
      backDelay: 1200,
      smartBackspace: true,
      loop: true,
      onStringTyped: function () {
        setStringIndex((idx) => (idx >= typedStrings.length - 1 ? 0 : idx + 1));
      },
    });
  }, []);

  return (
    <Wrapper>
      {/* <Helmet>
        <title>H</title>
      </Helmet> */}
      {/* <AnnouncementCTA>
        We've raised a $2.2M seed round!{" "}
        <b>
        <Link to={"/seed"}>Learn more →</Link>
        </b>
      </AnnouncementCTA>*/}
      <Title>
        Watch & browse
        <br />
        the web, <HighlightedText>together</HighlightedText>
      </Title>
      <HeroBackdrop />
      <Subtitle>
        From hosting virtual movie nights, browsing the web with friends or
        watching anime with your long-distance partner: Giggl lets you do that
        together in real-time.
      </Subtitle>
      <a target="_blank" rel="noopener" href="https://canary.giggl.app">
        <StyledCuteButton text={"Get Started"} />
      </a>

      <PortalReel />

      <GigglStepContainer>
        <div>
          <Step draggable={false} src={GigglStep1} />
          <h2>Create a Portal</h2>
          <p>
            A portal is an ephemeral collaborative browser which you can
            interact with in real-time and pass control off to other people. You
            can browse the internet, watch movies and stream directly to this
            space.
          </p>
        </div>
        <div>
          <FakeCreatePortalModal />
        </div>
      </GigglStepContainer>
      <GigglStepContainer rtl>
        <div>
          <FakePortalUserList />
        </div>
        <div>
          <Step draggable={false} src={GigglStep2} />
          <h2>Invite your Friends</h2>
          <p>
            Once created, share a link with friends (or invite through the app)
            and start browsing, together. As a portal host, you can choose who
            controls the browser.
          </p>
        </div>
      </GigglStepContainer>
      <GigglStepContainer>
        <div>
          <Step draggable={false} src={GigglStep3} />
          <h2>
            <span id="useCases"></span>...together
          </h2>
          <p>
            Now you can do everything you could usually do together online, but
            with others in real-time.
          </p>
        </div>
        <div>
          <FakeGigglClient contentType={typedStrings[stringIndex]} />
        </div>
      </GigglStepContainer>

      <Section secondary>
        <SectionContent>
          <SectionColumn style={{ width: "180%" }}>
            <CommsImage draggable={false} src={GigglStep4} />
          </SectionColumn>
          <SectionColumn>
            <h1>Voice & Text Chat</h1>
            <p>
              Giggl's high fidelity audio chat means you never miss a beat. An
              integrated chat allows you to create a feedback loop between
              portal members and content, with video chat coming soon.
            </p>
          </SectionColumn>
        </SectionContent>
      </Section>

      <Section>
        <FinalCTASectionContent column>
          <h1>Jump into the Future</h1>
          <p>
            Giggl is available in public beta for you to try out now. It's
            totally free.
          </p>
          <a target="_blank" rel="noopener" href="https://canary.giggl.app">
            <StyledCuteButton text={"Get Started"} />
          </a>
        </FinalCTASectionContent>
      </Section>
    </Wrapper>
  );
};

export default Home;
