import { Link } from "react-router-dom";
import styled from "styled-components";

import { ArrowUpRightIcon } from "./Icons";
import { WordmarkGhostWaveHappy } from "./BrandVectors";
import { THEME } from "../utils/Theme";

const Wrapper = styled.footer`
  width: 100%;
  box-sizing: border-box;
  background: #2c0034;
  margin-top: 90px;
  padding: 20px 12.5%;

  svg {
    height: 65px;
  }
`;

const Grid = styled.div`
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-template-rows: 1fr;
  gap: 10px 20px;
  padding: 20px 0px;
  box-sizing: border-box;

  @media screen and (max-width: 750px) {
    grid-template-columns: repeat(2, 2fr);

    div:first-child {
      grid-column: span 4;
      margin-bottom: 20px;
    }
  }
`;

const Column = styled.div`
  display: flex;
  grid-column: span 1;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
`;

const ColumnHeader = styled.h4`
  color: #fe0099;
  margin-bottom: 15px;
`;

interface IFooterLink {
  as?: "a";
  disabled?: boolean;
}

const FooterLink = styled(Link).attrs<IFooterLink>((props) => ({
  rel: !props.as ? null : "noopener",
  target: !props.as ? null : "_blank",
}))<IFooterLink>`
  color: ${({ disabled }) => (disabled ? THEME.textMuted : "#af97b4")};
  cursor: ${({ disabled }) => (disabled ? "default" : "pointer")};
  transition: color 200ms ease;
  line-height: 1.2;
  text-decoration: none;

  &:hover {
    color: ${({ theme, disabled }) =>
      disabled ? "#6a516f" : theme.textPrimary};
  }

  &:not(:first-of-type) {
    margin-top: 10px;
  }

  svg {
    display: inline-block;
    width: 14px;
    height: 14px;
    margin-left: 5px;
  }
`;

const Divider = styled.hr`
  width: 100%;
  height: 1px;
  background: ${({ theme }) => theme.bgSecondary};
  border: none;
`;

const Trail = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  color: #6a516f;
  padding: 20px 0px;

  span {
    margin-left: auto;
  }

  > a {
    margin-top: 0 !important;
  }

  a:not(:first-child) {
    margin-left: 15px;
  }
`;

const Footer = () => {
  return (
    <Wrapper>
      <Grid>
        <Column>
          <WordmarkGhostWaveHappy />
        </Column>
        <Column>
          <ColumnHeader>Product</ColumnHeader>
          <FooterLink as="a" href="https://canary.giggl.app">
            Launch Giggl
          </FooterLink>
          <FooterLink as="a" href="https://status.giggl.app">
            Server Status
            <ArrowUpRightIcon />
          </FooterLink>
        </Column>
        <Column>
          <ColumnHeader>Company</ColumnHeader>
          <FooterLink to="/jobs">Jobs</FooterLink>
          <FooterLink to={"/isp"}>ISP</FooterLink>
          <FooterLink disabled as="a">
            Investor Relations
            <ArrowUpRightIcon />
          </FooterLink>
        </Column>
        <Column>
          <ColumnHeader>Connect</ColumnHeader>
          <FooterLink as="a" href="https://discord.gg/mVAA2UpBVw">
            Discord <ArrowUpRightIcon />
          </FooterLink>
          <FooterLink as="a" href="https://twitter.com/gigglapp">
            Twitter <ArrowUpRightIcon />
          </FooterLink>
        </Column>
      </Grid>

      <Divider />

      <Trail>
        <FooterLink to="/terms">Terms</FooterLink>
        <FooterLink to="/privacy">Privacy</FooterLink>
        <span>&copy; Giggl, Inc</span>
      </Trail>
    </Wrapper>
  );
};

export default Footer;
