import { AnimatePresence, motion } from "framer-motion";
import styled from "styled-components";

import PlaceholderIcon from "../assets/images/gigglwaifu.png";
import PhinIcon from "../assets/images/avatars/phin.png";
import RossIcon from "../assets/images/avatars/ross.jpg";
import OliIcon from "../assets/images/avatars/oli.jpg";
import RobIcon from "../assets/images/avatars/rob.jpeg";
import AliIcon from "../assets/images/avatars/ali.jpeg";
import YannIcon from "../assets/images/avatars/liz3.jpeg";
import HappyFeetThumbnail from "../assets/images/thumbnails/happyfeet.png";
import LATripThumbnail from "../assets/images/thumbnails/latrip.png";
import MaiThumbnail from "../assets/images/thumbnails/mai.jpeg";
import LiveCodingThumbnail from "../assets/images/thumbnails/livecoding.jpeg";
import BlueprintThumbnail from "../assets/images/thumbnails/blueprint.jpeg";
import AppleEventThumbnail from "../assets/images/thumbnails/appleevent.jpeg";
import { useState } from "react";
import Button from "./Button";
import { THEME } from "../utils/Theme";

const Wrapper = styled(motion.div)`
  margin-top: 160px;
  display: flex;
  flex-direction: row;
  width: 100vw;
  overflow: hidden;
  transform: translate3d(0, 0, 0);
  /* margin: 0 auto; */
`;

const BlurOverlay = styled(motion.div)`
  left: 0;
  position: absolute;
  width: 100vw;
  height: 100%;
  backdrop-filter: blur(15px);
  background: rgba(0, 0, 0, 0.8);
  z-index: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  @keyframes blurFadeIn {
    from {
      background: rgba(0, 0, 0, 0);
    }
  }

  button {
    margin-top: 20px;
  }
`;

const Container = styled.div<{ secondary?: boolean }>`
  position: relative;
  display: flex;
  flex-direction: row;
  animation: moveSlideshow 30s linear infinite;

  @keyframes moveSlideshow {
    100% {
      transform: translateX(-100%);
    }
  }
`;

const PortalWrapper = styled.div`
  display: flex;
  flex-direction: column;
  margin-right: 30px;
`;

const PortalThumbnail = styled.div`
  width: 300px;
  height: 175px;
  background: grey;
  border-radius: 10px;
  overflow: hidden;
`;

const PortalThumbnailImage = styled.img`
  object-fit: cover;
  width: 100%;
  height: 100%;
`;

const PortalInfo = styled.div`
  margin-top: 10px;
  display: flex;
  flex-direction: row;
  line-height: 1.2;
  align-items: center;
`;

const Avatar = styled.img`
  width: 45px;
  height: 45px;
  border-radius: 50%;
`;

const PortalDetails = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin-left: 10px;
  color: ${THEME.textTertiary};

  b {
    color: ${({ theme }) => theme.textSecondary};
  }
`;

const portals = [
  {
    host_name: "rossstill",
    title: "Aviation Study Session",
    thumbnail: BlueprintThumbnail,
  },
  {
    host_name: "oli",
    title: "Happy Feet Watchparty",
    thumbnail: HappyFeetThumbnail,
    icon: OliIcon,
  },
  {
    host_name: "phin",
    title: "Movie Night: Bunny Girl Senpai",
    thumbnail: MaiThumbnail,
    icon: PhinIcon,
  },
  {
    host_name: "rob",
    title: "Peer Coding",
    thumbnail: LiveCodingThumbnail,
    icon: RobIcon,
  },
  {
    host_name: "liz3",
    title: "LA Trip Planning",
    icon: YannIcon,
    thumbnail: LATripThumbnail,
  },
  {
    host_name: "alistair",
    title: "Apple Event",
    icon: AliIcon,
    thumbnail: AppleEventThumbnail,
  },
];

const PortalReel = () => {
  const [showUpsell, setShowUpsell] = useState(false);

  return (
    <Wrapper
      onMouseEnter={() => setShowUpsell(true)}
      onMouseLeave={() => setShowUpsell(false)}
    >
      <AnimatePresence>
        {showUpsell ? (
          <BlurOverlay
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
            transition={{ ease: "easeOut", duration: 0.2 }}
          >
            <h3>Sign up to Giggl to view trending portals</h3>
            <a rel="noopener" target="_blank" href="https://canary.giggl.app">
              <Button>Sign Up</Button>
            </a>
          </BlurOverlay>
        ) : null}
      </AnimatePresence>
      <Portals />
      <Portals secondary />
    </Wrapper>
  );
};

const Portals = ({ secondary }: { secondary?: boolean }) => {
  return (
    <div>
      <Container secondary={secondary}>
        {portals.map((portal) => (
          <PortalWrapper key={portal.title}>
            <PortalThumbnail>
              <PortalThumbnailImage
                draggable={false}
                src={portal.thumbnail || PlaceholderIcon}
              />
            </PortalThumbnail>
            <PortalInfo>
              <Avatar draggable={false} src={portal.icon || RossIcon} />
              <PortalDetails>
                <b>{portal.title}</b>
                <span>@{portal.host_name}</span>
              </PortalDetails>
            </PortalInfo>
          </PortalWrapper>
        ))}
      </Container>
    </div>
  );
};

export default PortalReel;
