import { appThemes } from "@giggl/commons";

const DEV_OVERRIDES = {
  bgPrimary: "#16001a",
  bgSecondary: "#2c0034",
  textSecondary: "#f3ffe4",
  textTertiary: "#af97b4",
  textMuted: "#6a516f",
};

export const THEME = { ...appThemes.default, ...DEV_OVERRIDES };
