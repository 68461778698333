import { Helmet } from "react-helmet-async";
import styled from "styled-components";

const Wrapper = styled.div`
  padding-top: 90px;
  max-width: 65ch;

  h1,
  h2,
  h3,
  p {
    margin-bottom: 20px;
  }

  a {
    color: ${({ theme }) => theme.brandPrimary};

    &:hover {
      text-decoration: underline;
    }
  }
`;

const Privacy = () => (
  <Wrapper>
    <Helmet>
      <title>Privacy</title>
    </Helmet>
    <h1>Privacy Policy</h1>
    <p>
      <i>Last updated: 10/31/2020</i>
    </p>

    <h2>Introduction</h2>

    <p>
      Welcome to <b>Giggl, Inc.</b>
    </p>

    <p>
      Giggl, Inc. (“us”, “we”, or “our”) operates 
      <a href="https://giggl.app">giggl.app</a> and Giggl mobile
      application (hereinafter referred to as “<b>Service</b>”).
    </p>

    <p>
      Our Privacy Policy governs your visit to 
      <a href="https://giggl.app">giggl.app</a> and Giggl mobile application,
      and explains how we collect, safeguard and disclose information that
      results from your use of our Service.
    </p>

    <p>
      We use your data to provide and improve Service. By using Service, you
      agree to the collection and use of information in accordance with this
      policy. Unless otherwise defined in this Privacy Policy, the terms used in
      this Privacy Policy have the same meanings as in our Terms and Conditions.
    </p>

    <p>
      Our Terms and Conditions (“<b>Terms</b>”) govern all use of our Service
      and together with the Privacy Policy constitutes your agreement with us (“
      <b>agreement</b>”).
    </p>

    <h2>Definitions</h2>

    <p>
      <b>SERVICE</b> means the <a href="https://giggl.app">giggl.app</a> website
      and Giggl mobile application operated by Giggl, Inc.
    </p>

    <p>
      <b>PERSONAL DATA</b> means data about a living individual who can be
      identified from those data (or from those and other information either in
      our possession or likely to come into our possession).
    </p>

    <p>
      <b>USAGE DATA</b> is data collected automatically either generated by the
      use of Service or from Service infrastructure itself (for example, the
      duration of a page visit).
    </p>

    <p>
      <b>COOKIES</b> are small files stored on your device (computer or mobile
      device).
    </p>

    <p>
      <b>DATA CONTROLLER</b> means a natural or legal person who (either alone
      or jointly or in common with other persons) determines the purposes for
      which and the manner in which any personal data are, or are to be,
      processed. For the purpose of this Privacy Policy, we are a Data
      Controller of your data.
    </p>

    <p>
      <b>DATA PROCESSORS (OR SERVICE PROVIDERS)</b> means any natural or legal
      person who processes the data on behalf of the Data Controller. We may use
      the services of various Service Providers in order to process your data
      more effectively.
    </p>

    <p>
      <b>DATA SUBJECT</b> is any living individual who is the subject of
      Personal Data.
    </p>

    <p>
      <b>THE USER</b> is the individual using our Service. The User corresponds
      to the Data Subject, who is the subject of Personal Data.
    </p>

    <h2>Information Collection and Use</h2>

    <p>
      We collect several different types of information for various purposes to
      provide and improve our Service to you.
    </p>

    <h2>Types of Data Collected</h2>

    <p>
      <strong>Personal Data</strong>
    </p>
    <p>
      While using our Service, we may ask you to provide us with certain
      personally identifiable information that can be used to contact or
      identify you (“<b>Personal Data</b>”). Personally identifiable information
      may include, but is not limited to:
    </p>

    <ul>
      <li>Email address</li>

      <li>Phone number</li>

      <li>Cookies and Usage Data</li>
    </ul>

    <p style={{ marginTop: "0.9em" }}>
      We may use your Personal Data to contact you with newsletters, marketing
      or promotional materials and other information that may be of interest to
      you. You may opt out of receiving any, or all, of these communications
      from us by following the unsubscribe link.
    </p>

    <p>
      <strong>Usage Data</strong>
    </p>
    <p>
      We may also collect information that your browser sends whenever you visit
      our Service or when you access Service by or through a mobile device (“
      <b>Usage Data</b>”).
    </p>

    <p>
      This Usage Data may include information such as your computer's Internet
      Protocol address (e.g. IP address), browser type, browser version, the
      pages of our Service that you visit, the time and date of your visit, the
      time spent on those pages, unique device identifiers and other diagnostic
      data.
    </p>

    <p>
      When you access Service with a mobile device, this Usage Data may include
      information such as the type of mobile device you use, your mobile device
      unique ID, the IP address of your mobile device, your mobile operating
      system, the type of mobile Internet browser you use, unique device
      identifiers and other diagnostic data.
    </p>

    <p>
      <strong>Tracking Cookies Data</strong>
    </p>
    <p>
      We use cookies and similar tracking technologies to track the activity on
      our Service and we hold certain information.
    </p>

    <p>
      Cookies are files with a small amount of data which may include an
      anonymous unique identifier. Cookies are sent to your browser from a
      website and stored on your device. Other tracking technologies are also
      used such as beacons, tags and scripts to collect and track information
      and to improve and analyze our Service.
    </p>

    <p>
      You can instruct your browser to refuse all cookies or to indicate when a
      cookie is being sent. However, if you do not accept cookies, you may not
      be able to use some portions of our Service.
    </p>

    <p>Examples of Cookies we use:</p>

    <ul>
      <li>
        <strong>Session Cookies:</strong> We use Session Cookies to operate our
        Service.
      </li>

      <li>
        <strong>Preference Cookies:</strong> We use Preference Cookies to
        remember your preferences and various settings.
      </li>

      <li>
        <strong>Security Cookies:</strong> We use Security Cookies for security
        purposes.
      </li>
    </ul>

    <h2>Use of Data</h2>

    <p>Giggl, Inc. uses the collected data for various purposes:</p>

    <ul>
      <li>to provide and maintain our Service;</li>

      <li>to notify you about changes to our Service;</li>

      <li>
        to allow you to participate in interactive features of our Service when
        you choose to do so;
      </li>

      <li>to provide customer support;</li>

      <li>
        to gather analysis or valuable information so that we can improve our
        Service;
      </li>

      <li>to monitor the usage of our Service;</li>

      <li>to detect, prevent and address technical issues;</li>

      <li>to fulfill any other purpose for which you provide it;</li>

      <li>
        to carry out our obligations and enforce our rights arising from any
        contracts entered into between you and us, including for billing and
        collection;
      </li>

      <li>
        to provide you with notices about your account and/or subscription,
        including expiration and renewal notices, email-instructions, etc.;
      </li>

      <li>
        to provide you with news, special offers and general information about
        other goods, services and events which we offer that are similar to
        those that you have already purchased or enquired about unless you have
        opted not to receive such information;
      </li>

      <li>
        in any other way we may describe when you provide the information;
      </li>

      <li>for any other purpose with your consent.</li>
    </ul>

    <h2>Retention of Data</h2>

    <p>
      We will retain your Personal Data only for as long as is necessary for the
      purposes set out in this Privacy Policy. We will retain and use your
      Personal Data to the extent necessary to comply with our legal obligations
      (for example, if we are required to retain your data to comply with
      applicable laws), resolve disputes, and enforce our legal agreements and
      policies.
    </p>

    <p>
      We will also retain Usage Data for internal analysis purposes. Usage Data
      is generally retained for a shorter period, except when this data is used
      to strengthen the security or to improve the functionality of our Service,
      or we are legally obligated to retain this data for longer time periods.
    </p>

    <h2>Transfer of Data</h2>

    <p>
      Your information, including Personal Data, may be transferred to – and
      maintained on – computers located outside of your state, province, country
      or other governmental jurisdiction where the data protection laws may
      differ from those of your jurisdiction.
    </p>

    <p>
      If you are located outside United States and choose to provide information
      to us, please note that we transfer the data, including Personal Data, to
      United States and process it there.
    </p>

    <p>
      Your consent to this Privacy Policy followed by your submission of such
      information represents your agreement to that transfer.
    </p>

    <p>
      Giggl, Inc. will take all the steps reasonably necessary to ensure that
      your data is treated securely and in accordance with this Privacy Policy
      and no transfer of your Personal Data will take place to an organisation
      or a country unless there are adequate controls in place including the
      security of your data and other personal information.
    </p>

    <h2>Disclosure of Data</h2>

    <p>We may disclose personal information that we collect, or you provide:</p>

    <p>
      <strong>Disclosure for Law Enforcement.</strong>
    </p>
    <p>
      Under certain circumstances, we may be required to disclose your Personal
      Data if required to do so by law or in response to valid requests by
      public authorities.
    </p>

    <p>
      <strong>Business Transaction.</strong>
    </p>
    <p>
      If we or our subsidiaries are involved in a merger, acquisition or asset
      sale, your Personal Data may be transferred.
    </p>

    <p>
      <strong>Other cases. We may disclose your information also:</strong>
    </p>

    <ul>
      <li>to our subsidiaries and affiliates;</li>

      <li>
        to contractors, service providers, and other third parties we use to
        support our business;
      </li>

      <li>to fulfill the purpose for which you provide it;</li>

      <li>
        for any other purpose disclosed by us when you provide the information;
      </li>

      <li>with your consent in any other cases;</li>
    </ul>

    <h2 style={{ marginTop: "0.9em" }}>Security of Data</h2>

    <p>
      The security of your data is important to us but remember that no method
      of transmission over the Internet or method of electronic storage is 100%
      secure. While we strive to use commercially acceptable means to protect
      your Personal Data, we cannot guarantee its absolute security.
    </p>

    <h2>
      Your Data Protection Rights Under General Data Protection Regulation
      (GDPR)
    </h2>

    <p>
      If you are a resident of the European Union (EU) and European Economic
      Area (EEA), you have certain data protection rights, covered by GDPR. –
      See more at{" "}
      <a href="https://eur-lex.europa.eu/eli/reg/2016/679/oj">
        https://eur-lex.europa.eu/eli/reg/2016/679/oj
      </a>
    </p>

    <p>
      We aim to take reasonable steps to allow you to correct, amend, delete, or
      limit the use of your Personal Data.
    </p>

    <p>
      If you wish to be informed what Personal Data we hold about you and if you
      want it to be removed from our systems, please email us at{" "}
      <a href="mailto:developers@giggl.app">developers@giggl.app</a>.
    </p>

    <p>
      In certain circumstances, you have the following data protection rights:
    </p>

    <ol>
      <li>
        the right to access, update or to delete the information we have on you;
      </li>

      <li>
        the right of rectification. You have the right to have your information
        rectified if that information is inaccurate or incomplete;
      </li>

      <li>
        the right to object. You have the right to object to our processing of
        your Personal Data;
      </li>

      <li>
        the right of restriction. You have the right to request that we restrict
        the processing of your personal information;
      </li>

      <li>
        the right to data portability. You have the right to be provided with a
        copy of your Personal Data in a structured, machine-readable and
        commonly used format;
      </li>

      <li>
        the right to withdraw consent. You also have the right to withdraw your
        consent at any time where we rely on your consent to process your
        personal information.
      </li>
    </ol>

    <p style={{ marginTop: "1.2em" }}>
      Please note that we may ask you to verify your identity before responding
      to such requests. Please note, we may not able to provide Service without
      some necessary data.
    </p>

    <p>
      You have the right to complain to a Data Protection Authority about our
      collection and use of your Personal Data. For more information, please
      contact your local data protection authority in the European Economic Area
      (EEA).
    </p>

    <h2>
      Your Data Protection Rights under the California Privacy Protection Act
      (CalOPPA)
    </h2>

    <p>
      CalOPPA is the first state law in the nation to require commercial
      websites and online services to post a privacy policy. The law’s reach
      stretches well beyond California to require a person or company in the
      United States (and conceivable the world) that operates websites
      collecting personally identifiable information from California consumers
      to post a conspicuous privacy policy on its website stating exactly the
      information being collected and those individuals with whom it is being
      shared, and to comply with this policy. – See more at:{" "}
      <a href="https://consumercal.org/about-cfc/cfc-education-foundation/california-online-privacy-protection-act-caloppa-3/">
        https://consumercal.org/about-cfc/cfc-education-foundation/california-online-privacy-protection-act-caloppa-3/
      </a>
    </p>

    <p>According to CalOPPA we agree to the following:</p>

    <p>users can visit our site anonymously;</p>

    <p>
      our Privacy Policy link includes the word “Privacy”, and can easily be
      found on the page specified above on the home page of our website;
    </p>

    <p>
      users will be notified of any privacy policy changes on our Privacy Policy
      Page;
    </p>

    <p>
      users are able to change their personal information by emailing us at{" "}
      <a href="mailto:developers@giggl.app">developers@giggl.app</a>.
    </p>

    <p>Our Policy on “Do Not Track” Signals:</p>

    <p>
      We honor Do Not Track signals and do not track, plant cookies, or use
      advertising when a Do Not Track browser mechanism is in place. Do Not
      Track is a preference you can set in your web browser to inform websites
      that you do not want to be tracked.
    </p>

    <p>
      You can enable or disable Do Not Track by visiting the Preferences or
      Settings page of your web browser.
    </p>

    <h2>Analytics</h2>

    <p>
      We may use third-party Service Providers to monitor and analyze the use of
      our Service.
    </p>

    <p>
      <strong>Firebase</strong>
    </p>
    <p>Firebase is analytics service provided by Google Inc.</p>

    <p>
      You may opt-out of certain Firebase features through your mobile device
      settings, such as your device advertising settings or by following the
      instructions provided by Google in their Privacy Policy:{" "}
      <a href="https://policies.google.com/privacy?hl=en">
        https://policies.google.com/privacy?hl=en
      </a>
    </p>

    <p>
      For more information on what type of information Firebase collects, please
      visit the Google Privacy Terms web page:{" "}
      <a href="https://policies.google.com/privacy?hl=en">
        https://policies.google.com/privacy?hl=en
      </a>
    </p>

    <p>
      <strong>Mixpanel</strong>
    </p>
    <p>Mixpanel is an analytics service provided by Mixpanel, Inc.</p>

    <p>
      You can prevent Mixpanel from using your information for analytics
      purposes by opting-out. To opt-out of Mixpanel service, please visit this
      page:{" "}
      <a href="https://mixpanel.com/optout">https://mixpanel.com/optout</a>
    </p>

    <p>
      For more information on what type of information Mixpanel collects, please
      visit the Terms of Use page of Mixpanel:{" "}
      <a href="https://mixpanel.com/terms">https://mixpanel.com/terms</a>
    </p>

    <h2>CI/CD tools</h2>

    <p>
      We may use third-party Service Providers to automate the development
      process of our Service.
    </p>

    <p>
      <strong>GitHub</strong>
    </p>

    <p>GitHub is provided by GitHub, Inc.</p>

    <p>
      GitHub is a development platform to host and review code, manage projects,
      and build software.
    </p>

    <p>
      For more information on what data GitHub collects for what purpose and how
      the protection of the data is ensured, please visit GitHub Privacy Policy
      page:{" "}
      <a href="https://help.github.com/en/articles/github-privacy-statement">
        https://help.github.com/en/articles/github-privacy-statement
      </a>
      .
    </p>

    <h2>Payments</h2>

    <p>
      We may provide paid products and/or services within Service. In that case,
      we use third-party services for payment processing (e.g. payment
      processors).
    </p>

    <p>
      We will not store or collect your payment card details. That information
      is provided directly to our third-party payment processors whose use of
      your personal information is governed by their Privacy Policy. These
      payment processors adhere to the standards set by PCI-DSS as managed by
      the PCI Security Standards Council, which is a joint effort of brands like
      Visa, Mastercard, American Express and Discover. PCI-DSS requirements help
      ensure the secure handling of payment information.
    </p>

    <p>The payment processors we work with are:</p>

    <p>
      <strong>Stripe:</strong>
    </p>
    <p>
      Their Privacy Policy can be viewed at:{" "}
      <a href="https://stripe.com/us/privacy">https://stripe.com/us/privacy</a>
    </p>

    <h2>Links to Other Sites</h2>

    <p>
      Our Service may contain links to other sites that are not operated by us.
      If you click a third party link, you will be directed to that third
      party's site. We strongly advise you to review the Privacy Policy of every
      site you visit.
    </p>

    <p>
      We have no control over and assume no responsibility for the content,
      privacy policies or practices of any third party sites or services.
    </p>

    <h2>Children's Privacy</h2>

    <p>
      Our Services are not intended for use by children under the age of 13 (“
      <b>Children</b>”).
    </p>

    <p>
      We do not knowingly collect personally identifiable information from
      Children under 13. If you become aware that a Child has provided us with
      Personal Data, please contact us. If we become aware that we have
      collected Personal Data from Children without verification of parental
      consent, we take steps to remove that information from our servers.
    </p>

    <h2>Changes to This Privacy Policy</h2>

    <p>
      We may update our Privacy Policy from time to time. We will notify you of
      any changes by posting the new Privacy Policy on this page.
    </p>

    <p>
      We will let you know via email and/or a prominent notice on our Service,
      prior to the change becoming effective and update “effective date” at the
      top of this Privacy Policy.
    </p>

    <p>
      You are advised to review this Privacy Policy periodically for any
      changes. Changes to this Privacy Policy are effective when they are posted
      on this page.
    </p>

    <h2>Contact Us</h2>

    <p>
      If you have any questions about this Privacy Policy, please contact us:
    </p>

    <p>
      By email: <a href="mailto:developers@giggl.app">developers@giggl.app</a>.
    </p>
  </Wrapper>
);

export default Privacy;
