import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import styled from "styled-components";
import { GhostWaveHappy } from "./BrandVectors";
import Button from "./Button";

const Bar = styled.div<{ engaged?: boolean }>`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  width: 100vw;
  height: 60px;
  box-sizing: border-box;
  padding-left: 12.5%;
  padding-right: 12.5%;
  backdrop-filter: ${(props) => props.engaged && "blur(5px)"};
  position: fixed;
  top: 0;
  border-bottom: ${(props) =>
    props.engaged
      ? "1px solid rgba(255, 255, 255, 0.1)"
      : "1px solid transparent"};
  transition: border-bottom 150ms ease 0s, top 250ms ease 0s,
    background-color 300ms ease 0s;
  z-index: 2;
  background-color: ${(props) =>
    props.engaged ? "#2c0034" + "a5" : "transparent"};
  svg {
    height: 35px;
    width: 35px;
    margin-right: 85px;
  }
`;

const Navigation = styled.div`
  display: flex;
  flex-grow: 1;
  align-items: center;
  justify-content: center;
  @media (max-width: 800px) {
    display: none;
  }
`;

const NavigationContent = styled.div`
  padding: 0 40px;
  display: flex;
  max-width: 400px;
  flex-direction: row;
  flex-grow: 1;
  justify-content: space-between;
  a {
    flex-shrink: 0;
    text-decoration: none;
    color: ${({ theme }) => theme.textSecondary};
    font-size: 15px;
    transition: color 0.2s ease;
    &:hover {
      color: ${(props) => props.theme.textPrimary};
    }
  }
`;

const LaunchButton = styled(Button)`
  /* box-shadow: 0px 10px 40px -10px rgba(255, 0, 131, 0.7); */
`;

const Nav = () => {
  const [engaged, setEngaged] = useState(false);
  useEffect(() => {
    const setFromEvent = () => setEngaged(window.scrollY > 99);

    window.addEventListener("scroll", setFromEvent);
    return () => {
      window.removeEventListener("scroll", setFromEvent);
    };
  }, []);

  return (
    <Bar engaged={engaged}>
      <Link to={"/"}>
        <GhostWaveHappy />
      </Link>

      <Navigation>
        <NavigationContent>
          <Link to="/">Home</Link>
          <Link to="/jobs">Jobs</Link>
          <a target="_blank" href="https://twitter.com/gigglapp">
            Twitter
          </a>
          {/* <Link to="/">Bots</Link> */}
        </NavigationContent>
      </Navigation>

      <LaunchButton onClick={() => window.open("https://canary.giggl.app")}>
        Launch Giggl
      </LaunchButton>
    </Bar>
  );
};

export default Nav;
